import { Lang, MultiLangHelper } from "../../../shared/multi.lang";

export interface ILangSupport {
    lang: Lang;

    help_previewWillBeShown: string;
    help_inCaseOfAProblem: string;
    help_pleaseDoCheck: string;
    help_editTheFile: string;
    help_activeByDefault: string;
    help_youMayDisableIt: string;

    selectFile: string;
    import: string;
    hashtags: string;

    'BarcodeController.Import.request': string;
    'BarcodeController.Import.File': string;
    'BarcodeController.Import.exelfilerows': string;
    'BarcodeController.Import.CompanyId': string;
    'BarcodeController.Import.company': string;
    'BarcodeController.Import.barcodeSet': string;
    'BarcodeController.Import': string;
}

class TurkishSupport implements ILangSupport {
    lang = Lang.TR;

    help_previewWillBeShown = "yüklemek üzere seçeceğiniz dosya, yükleme işleminden önce hızlıca göz gezdirmeniz için size gösterilecek";
    help_inCaseOfAProblem = "dosya içeriğinin size gösterilememesi veya kayıtların hatalı görünmesi, yükleme işleminden sonra da bir problem olacağına işaret ediyor olabilir";
    help_pleaseDoCheck = "lütfen kayıtları inceleyip, doğruluklarını son bir defa kontrol ediniz";
    help_editTheFile = "eğer düzeltmeniz gereken bir kayıt varsa, lütfen bu kaydı dosya üzerinde düzeltin ve dosyayı tekrar yükleyin";
    help_activeByDefault = "kayıtlar başarı ile aktarıldığı andan itibaren arama sonuçlarında görüntülenecektir";
    help_youMayDisableIt = "aktarma geçmişi sekmesini kullanarak bu kayıtların arama sonuçlarında görünmesini engelleyebilirsiniz";

    selectFile = "dosya seç";
    import = "aktar";
    hashtags = "hashtag'ler (her hashtag'den sonra 'enter'a basın)";

    'BarcodeController.Import.request' = "yapmaya çalıştığınız istek sunucuya ulaşmadı";
    'BarcodeController.Import.File' = "yüklemeye çalıştığınız dosya sunucuya ulaşmadı";
    'BarcodeController.Import.exelfilerows' = "Lütfen  tek seferde 50.000 adetten daha fazla data yüklemeyin";
    'BarcodeController.Import.CompanyId' = "dosya yüklemek istediğiniz şirket bilgisi sunucuya ulaşmadı";
    'BarcodeController.Import.company' = "şirket kaydı bulunamadı";
    'BarcodeController.Import.barcodeSet' = "aktarma işlemi sırasında bir sorun oluştu, lütfen dosyayı kontrol edip tekrar deneyin";
    'BarcodeController.Import' = "dosya aktarma işlemi başarıyla gerçekleşti";
}
const turkishSupport = new TurkishSupport();

class EnglishSupport implements ILangSupport {
    lang = Lang.EN;

    help_previewWillBeShown = "the file you want to upload will be shown to you before the upload operation in order for you to quickly check it out";
    help_inCaseOfAProblem = "it might be a sign of a problem if the file contents are not being shown or the records are corrupt";
    help_pleaseDoCheck = "please check on the records one last time before you upload the file";
    help_editTheFile = "in case there's a record you need to fix, please fix that record on the file and then upload the file again";
    help_activeByDefault = "records will be avaible once your import process is completed successfully";
    help_youMayDisableIt = "you may prevent these records from showing up on search results via the 'import history' tab";

    selectFile = "select file";
    import = "import";
    hashtags = "hashtags (press 'enter' after each hastag)";

    'BarcodeController.Import.request' = "your request couldn't make it's way to the server";
    'BarcodeController.Import.File' = "the file you're trying to upload couldn't make it's way to the server";
    'BarcodeController.Import.exelfilerows' = "please don't upload more than 50.000 pieces of data at a time";
    'BarcodeController.Import.CompanyId' = "information of the company you want to relate to this file couldn't make it's way to server";
    'BarcodeController.Import.company' = "couldn't find company record";
    'BarcodeController.Import.barcodeSet' = "something went wrong during the file import process, please check the file and try again";
    'BarcodeController.Import' = "file import was successful";
}
const englishSupport = new EnglishSupport();

const langHelper = new MultiLangHelper<ILangSupport>(turkishSupport, englishSupport);
export { langHelper }
