import { Lang, MultiLangHelper } from "../shared/multi.lang";

export interface ILangSupport {
    lang: Lang;

    adminPanel: string;
}

class TurkishSupport implements ILangSupport {
    lang = Lang.TR;

    adminPanel = "Admin Paneli";
}
const turkishSupport = new TurkishSupport();

class EnglishSupport implements ILangSupport {
    lang = Lang.EN;

    adminPanel = "Admin Panel";
}
const englishSupport = new EnglishSupport();

const langHelper = new MultiLangHelper<ILangSupport>(turkishSupport, englishSupport);
export { langHelper }
