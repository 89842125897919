import * as XLSX from 'ts-xlsx';

export class ExcelReader {

    private readonly fileReader = new FileReader();

    read = async (file: File): Promise<IExcelSheet[]> => {

        this.fileReader.readAsArrayBuffer(file);

        return new Promise((resolve, reject) => {
            try {
                this.fileReader.onload = (e) => {
                    let arrayBuffer: any = this.fileReader.result;
                    let data = new Uint8Array(arrayBuffer);
                    let arr = [];
                    for (let i = 0; i < data.length; ++i)
                        arr.push(String.fromCharCode(data[i]));
                    let bstr = arr.join("");
                    let workbook = XLSX.read(bstr, { type: "binary" });

                    let sheets: IExcelSheet[] = [];
                    for (let i = 0; i < workbook.SheetNames.length; i++) {
                        let sheetName = workbook.SheetNames[i];
                        let worksheet = workbook.Sheets[sheetName];
                        let json = XLSX.utils.sheet_to_json(worksheet, { raw: true });

                        let rows: IExcelRow[] = [];
                        for (let j = 0; j < json.length; j++) {
                            const element: any = json[j];

                            let entries = Object.entries<any>(element);

                            let cells: IExcelCell[] = [];
                            for (let k = 0; k < entries.length; k++) {
                                const property = entries[k];
                                let cell: IExcelCell = { name: property[0], value: property[1] };
                                cells.push(cell);
                            }

                            let row: IExcelRow = { cells: cells };
                            rows.push(row);
                        }
                        let sheet: IExcelSheet = { rows: rows };
                        sheets.push(sheet);
                    }

                    resolve(sheets);
                }
            } catch (error) {
                reject(error);
            }
        });
    }
}

export interface IExcelSheet {
    rows: IExcelRow[];
}

export interface IExcelRow {
    cells: IExcelCell[];
}

export interface IExcelCell {
    name: string;
    value: any;
}
