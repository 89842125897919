import { faHashtag, faQuestionCircle, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button, Card, Col, Collapse, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { ILangSupport, langHelper } from './EditBarcodeSet.lang';
import { ColorCodes } from '../../../shared/models';
import { FileUpload } from '../../../shared/FileUpload';
import { FadeAlert, FadeAlertMessage } from '../../../shared/FadeAlert';
import { barcodeRequester, IEditBarcodeSetRequest } from '../Barcode.requester';
import { Tags } from '../../../shared/Tags';
import { IBarcodeSet } from '../Barcode.models';

export class EditBarcodeSet extends React.Component<IProps, IState> {

    fileUploadRef = React.createRef<FileUpload>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            ls: langHelper.Current,

            tags: props.barcodeSet.Tags,

            isHelpOn: false,
            alert: FadeAlertMessage.default,

            submitting: false
        };
    }

    componentDidMount = async (): Promise<void> => {
        langHelper.RegisterComponent(this);
    }
    componentWillUnmount = (): void => {
        langHelper.UnregisterComponent(this);
    }

    private helpButtonClicked = (): void => {
        this.setState({ isHelpOn: !this.state.isHelpOn });
    }

    private onTagsChange = (tags: string[]): void => {
        this.setState({ tags: tags });
    };

    submit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();

        let props = this.props;
        let state = this.state;
        let ls = state.ls;

        this.setState({ submitting: true, alert: FadeAlertMessage.default }, async () => {
            try {
                let request: IEditBarcodeSetRequest = {
                    Id: props.barcodeSet.Id,
                    Tags: state.tags
                };

                let response = await barcodeRequester.EditBarcodeSet(request);
                let message = langHelper.GetViaKey(ls, response.Message);
                let color = response.Success ? ColorCodes.success : ColorCodes.warning;

                this.setState({ submitting: false, alert: new FadeAlertMessage(message, color) }, () => {
                    if (props.onSubmit)
                        props.onSubmit({});
                });
            } catch (error) {
                let e = error as Error;
                let alertMessage = new FadeAlertMessage(e.message, ColorCodes.danger);
                this.setState({ alert: alertMessage, submitting: false });
            }
        });
    }

    render() {
        let state = this.state;
        let ls = state.ls;

        return (
            <div>
                <Card body>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <Button className="btn-sm" color={ColorCodes.dark} onClick={this.helpButtonClicked} style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </Button>
                            <Collapse isOpen={state.isHelpOn}>
                                <Card body>
                                    <ul>
                                        <li>{ls.help_default}</li>
                                    </ul>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={faHashtag} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Tags placeHolder={ls.hashtags} value={state.tags} onChange={this.onTagsChange} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <FadeAlert alert={state.alert} tag="span" className="mt-3" />
                            <div className="d-flex justify-content-center">
                                <Button className="btn-sm btn-bep" disabled={state.submitting} onClick={this.submit}>
                                    <FontAwesomeIcon icon={faSave} />&nbsp;
                                    {ls.save}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

interface IProps {
    barcodeSet: IBarcodeSet;
    onSubmit?: { (e: IOnSubmitEvent): void }
}

interface IState {
    ls: ILangSupport;

    tags: string[];

    isHelpOn: boolean;
    alert: FadeAlertMessage;

    submitting: boolean;
}

export interface IOnSubmitEvent {

}
