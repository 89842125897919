import { Lang, MultiLangHelper } from "../../../shared/multi.lang";

export interface ILangSupport {
    lang: Lang;

    help_ReqiredFields: string;
    help_InformationalCompanyDetails: string;

    companyName: string;
    companyCode: string;
    email: string;
    phone: string;
    originalUrl: string;
    suspiciousImage: string;
    originalImage: string;

    save: string;

    'CompanyController.Edit.Id': string;
    'CompanyController.Edit.Name': string;
    'CompanyController.Edit.Code': string;
    'CompanyController.Edit.Email': string;
    'CompanyController.Edit.Phone': string;
    'CompanyController.Edit.NotFoundImage': string;
    'CompanyController.Edit.imgtype': string;
    'CompanyController.Edit.SuspiciousImage': string;
    'CompanyController.Edit.OriginalImage': string;
    'CompanyController.Edit.existingCompany': string;
    'CompanyController.Edit.companyViaCode': string;
    'CompanyController.Edit.company': string;
    'CompanyController.Edit': string;
}

class TurkishSupport implements ILangSupport {
    lang = Lang.TR;

    help_ReqiredFields = "(*) ile işaretlenmiş alanlar zorunlu alanlardır";
    help_InformationalCompanyDetails = "'eposta' ve 'telefon numarası' gibi alanlar zorunlu olmamakla beraber, kullanıcıların telefon ekranından kolayca bu bilgileri kopyalayabilmesi için tasarlandı";

    companyName = "şirket adı";
    companyCode = "şirket kodu";
    email = "eposta";
    phone = "telefon numarası";

    suspiciousUrl = "'şüpheli kod' linki";
    originalUrl = "ürün garanti etme linki";

    suspiciousImage = "'şüpheli kod' görseli";
    originalImage = "'orijinal kod' görseli";
    
    save = "kaydet";
    'CompanyController.Edit.Id' = "id bilgisi eksik";
    'CompanyController.Edit.Name' = "lütfen şirket adı belirtin";
    'CompanyController.Edit.Code' = "lütfen şirket kodu belirtin";
    'CompanyController.Edit.Email' = "lütfen geçerli bir email belirtin";
    'CompanyController.Edit.Phone' = "lütfen geçerli bir telefon numarası belirtin";
    'CompanyController.Edit.OriginalUrl' = "lütfen geçerli bir 'orijinal kod' linki belirtin";
    'CompanyController.Edit.SuspiciousUrl' = "lütfen geçerli bir 'şüpheli kod' linki belirtin";
    'CompanyController.Edit.imgtype' = "lütfen sadece png formatında görsel yükleyin";
    'CompanyController.Edit.NotFoundImage' = "lütfen 'bilinmeyen kod' görseli ekleyin";
    'CompanyController.Edit.SuspiciousImage' = "lütfen 'şüpheli kod' görseli ekleyin";
    'CompanyController.Edit.OriginalImage' = "lütfen 'orijinal kod' görseli ekleyin";
    'CompanyController.Edit.existingCompany' = "bu ismi kullanan başka bir şirket mevcut";
    'CompanyController.Edit.companyViaCode' = "aynı koda sahip başka bir şirket zaten mevcut";
    'CompanyController.Edit.company' = "düzenlemek istediğiniz şirket'in kaydı silinmiş olabilir";
    'CompanyController.Edit' = "şirket kaydı başarıyla güncellendi";
}
const turkishSupport = new TurkishSupport();

class EnglishSupport implements ILangSupport {
    lang = Lang.EN;

    help_ReqiredFields = "fields that are marked with (*) are required";
    help_InformationalCompanyDetails = "even thought fields like 'email' and 'phone number' are not required, they are designed to help mobile users in order for them to easily copy those informations";

    companyName = "company name";
    companyCode = "company code";
    email = "email";
    phone = "phone number";

    suspiciousUrl = "'suspicious code' link";
    originalUrl = "product warranty link";

    suspiciousImage = "'suspicious code' image";
    originalImage = "'original code' image";

    save = "save";
    'CompanyController.Edit.Id' = "id information is missing";
    'CompanyController.Edit.Name' = "please provide the company name";
    'CompanyController.Edit.Code' = "please provide the company code";
    'CompanyController.Edit.Email' = "please provide a valid email";
    'CompanyController.Edit.Phone' = "please provide a valid phone number";
    'CompanyController.Edit.NotFoundImage' = "please provide the 'unkown code' image";
    'CompanyController.Edit.SuspiciousImage' = "please provide the 'suspicious code' image";
    'CompanyController.Edit.OriginalImage' = "please provide the 'original code' image";
    'CompanyController.Edit.imgtype' = "please upload images in png format only";

    'CompanyController.Edit.existingCompany' = "there is already another company using that name";
    'CompanyController.Edit.companyViaCode' = "there's another company using the same code";
    'CompanyController.Edit.company' = "the company you're trying to edit might have been deleted";
    'CompanyController.Edit' = "company record was successfully edited";
}
const englishSupport = new EnglishSupport();

const langHelper = new MultiLangHelper<ILangSupport>(turkishSupport, englishSupport);
export { langHelper }
