import { Lang, MultiLangHelper } from "../../../shared/multi.lang";

export interface ILangSupport {
    lang: Lang;

    search: string;
    setPassive: string;
    setActive: string;
    records: string;
    importFailed: string;
    edit: string;

    help_startTyping: string;
    help_justPressEnter: string;
    help_searchProperties: string;
    help_searchProperties_1: string;
    help_searchProperties_2: string;
    help_searchProperties_2_1: string;
    help_searchProperties_2_2: string;
    help_searchProperties_3: string;
    help_searchProperties_3_1: string;
    help_searchProperties_3_2: string;
    help_searchProperties_4: string;
    help_searchProperties_5: string;
    help_searchProperties_5_1: string;
    help_searchProperties_5_2: string;
    help_searchProperties_5_3: string;
    help_searchProperties_5_4: string;
    help_searchProperties_5_5: string;
    help_downloadFile: string;
    help_activePassive: string;
    help_duplications: string;
}

class TurkishSupport implements ILangSupport {
    lang = Lang.TR;

    search = "ara";
    setPassive = "pasif yap";
    setActive = "aktif yap";
    records = "kayıt";
    importFailed = "aktarım başarısız";
    edit = "düzenle";

    help_startTyping = "arama kutucuğuna bulmak istediğiniz dosyanın ismini yazmaya başlayın, arama işlemi siz yazmayı bıraktıktan kısa bir süre sonra otomatik olarak başlayacaktır";
    help_justPressEnter = "eğer beklemek istemiyorsanız enter'a basarak arama işlemini anında başlatabilirsiniz";
    help_searchProperties = "arama kutucuğunu kullanarak dosya ismi, barkod sayısı, hashtag ve barkod araması yapabilirsiniz. örneğin;";
    help_searchProperties_1 = "10 yazdığınızda arama sonucu size 10 ve üzeri kaydı olan aktarımları gösterir";
    help_searchProperties_2 = "eğer aktardığınız dosyaların içeriğinde arama yapmak istiyorsanız \" >_ \" özel kodunu kullanmalısınız. örneğin;";
    help_searchProperties_2_1 = ">_KS8PZ8J yazarsanız \"KS8PZ8J\" kaydını içeren aktarımları listeler";
    help_searchProperties_2_2 = ">_8PZ yazarsanız \"KS8PZ8J\" kaydını içeren aktarımları listelediği gibi \"8PZ\" kaydını içeren başka aktarımları da listeler";
    help_searchProperties_3 = "eğer aktarımlarınızın hashtag'i ile arama yapmak istiyorsanız \" # \" özel kodunu kullanmalısınız. örneğin;";
    help_searchProperties_3_1 = "#pantolon yazarsanız \"pantolon\" etiketine sahip tüm aktarımları listeler";
    help_searchProperties_3_2 = "#pa yazarsanız \"pantolon\" etiketine sahip aktarımları listelediği gibi etiketinde \"pa\" geçen başka aktarımları da listeler (#pardesü gibi)";
    help_searchProperties_4 = "özel kod kullanmadan yazdığınız her kelime normal arama olarak çalışır, sayılar dahil";
    help_searchProperties_5 = "yukarıda sayılan özellikleri farklı kombinasyonlarla kullanabilirsiniz. örneğin;";
    help_searchProperties_5_1 = "yaz 500 #kadın >_KSPJKAA #pantolon ilkbahar >_KSR0RTP";
    help_searchProperties_5_2 = "dosya isminde \"ilkbahar\" ve \"yaz\" kelimeleri veya 500 sayısı geçen ve";
    help_searchProperties_5_3 = "aktarım dosyasında 500'den fazla kaydı olan ve";
    help_searchProperties_5_4 = "aktarım dosyasında \"KSPJKAA\" ve \"KSR0RTP\" barkodları olan ve";
    help_searchProperties_5_5 = "\"kadın\" ve \"pantolon\" hastagleri içeren aktarımlar";
    help_downloadFile = "listelenen kutulardaki linklere tıklayarak içe aktardığınız dosyanın bir kopyasını indirebilirsiniz";
    help_activePassive = "her bir kutuyu ayrı ayrı aktif/pasif olarak işaretleyerek arama sonuçlarına dahil olmalarını sağlayabilir veya engelleyebilirsiniz";
    help_duplications = "eğer aktif dosyalar içinde birbirinin aynısı birden fazla kayda rastlanırsa, arama sonucunda bu kayıtlardan en son aktardığınız kayıt gösterilir";
}
const turkishSupport = new TurkishSupport();

class EnglishSupport implements ILangSupport {
    lang = Lang.EN;

    search = "search";
    setPassive = "set as passive";
    setActive = "set as active";
    records = "records";
    importFailed = "import failed";
    edit = "güncelle";

    help_startTyping = "start typing the name of the file you want to find, the search operation will start right after you stop typing";
    help_justPressEnter = "in case you don't want to wait, you might as well press 'enter' in order to start the search operation right away";
    help_searchProperties = "you may perform search over file name, barcode count and barcode itself. for example;";
    help_searchProperties_1 = "when you type 10, the search result will contain imports which has 10 or more records in it";
    help_searchProperties_2 = "you need to use \" >_ \" special code if you want to perform a search over imported barcodes. for example;";
    help_searchProperties_2_1 = "if you type >_KS8PZ8J , any imports containing \"KS8PZ8J\" record will be listed";
    help_searchProperties_2_2 = "if you type >_8PZ , any imports containing \"8PZ\" record will be listed, including \"KS8PZ8J\"";
    help_searchProperties_3 = "you need to use \" # \" special code if you want to perform a search over the hashtags of your imports. for example;";
    help_searchProperties_3_1 = "if you type #women , any imports with \"women\" tag will be listed";
    help_searchProperties_3_2 = "if you type #men , any imports which has a tag that contains \"men\" will be listed, including \"women\"";
    help_searchProperties_4 = "any words you type without using these special codes, including numbers, will be treated as a regular search";
    help_searchProperties_5 = "you may combine all these properties listed above. for example;";
    help_searchProperties_5_1 = "summer 500 #women >_KSPJKAA #pants sprint >_KSR0RTP";
    help_searchProperties_5_2 = "file name contains \"summer\" & \"sprint\" or the number 500 and";
    help_searchProperties_5_3 = "import file has more than 500 records and";
    help_searchProperties_5_4 = "import file has \"KSPJKAA\" & \"KSR0RTP\" barcodes and";
    help_searchProperties_5_5 = "import has \"women\" & \"pants\" hastags";
    help_downloadFile = "you may follow the links in these boxes below if you would like to download a copy of the file you imported";
    help_activePassive = "you may set each box as active/passive in order for them to be included into or excluded from the search results";
    help_duplications = "in case of a search result having duplicate records because of more than one file having the same record, we will show the one you've imported the last";
}
const englishSupport = new EnglishSupport();

const langHelper = new MultiLangHelper<ILangSupport>(turkishSupport, englishSupport);
export { langHelper }
