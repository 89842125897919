import * as React from 'react';
import { Table } from 'reactstrap';
import { IExcelCell, IExcelSheet } from '../shared/ExcelHelpers';
import { ILangSupport, langHelper } from './ExcelPreview.lang';

export class ExcelPreview extends React.Component<IProps, IState>  {

    constructor(props: IProps) {
        super(props);
        this.state = {
            ls: langHelper.Current
        };
    }

    componentDidMount = (): void => {
        langHelper.RegisterComponent(this);

    }
    componentWillUnmount = (): void => {
        langHelper.UnregisterComponent(this);
    }

    isInvalid = (cell: IExcelCell): boolean => {
        return cell === null || cell === undefined || cell.value === undefined;
    }

    render() {
        let props = this.props;

        let state = this.state;
        let ls = state.ls;

        let tableStyle: React.CSSProperties = {
            maxHeight: '295px', overflowY: 'scroll', width: '100%',
            overflowX: 'hidden', border: '1px solid rgba(0, 0, 0, 0.125)',
            borderTop: 0, scrollbarWidth: 'thin'
        };

        let rows = props.sheet.rows;
        let issueCount = 0;
        for (let i = 0; i < rows.length; i++) {
            try {
                const cells = rows[i].cells;
                var anyIssues = this.isInvalid(cells[0]) || this.isInvalid(cells[1]) || this.isInvalid(cells[2]);
                if (anyIssues)
                    issueCount++;
            } catch (error) {
                issueCount++;
            }
        }

        let infoStyle: React.CSSProperties = {
            float: 'right', padding: '1%',
            color: issueCount ? '#721c24' : '#696969',
            backgroundColor: issueCount ? 'rgb(248, 215, 218)' : 'initial'
        };

        if (rows.length > 0) {
            let firstRow = rows[0];
            return (
                <div style={{ width: '100%' }}>
                    <div style={tableStyle}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>{firstRow.cells[0].name}</th>
                                    <th>{firstRow.cells[1].name}</th>
                                    <th>{firstRow.cells[2].name}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rows.map((row, i) => {
                                        let cells = row.cells;
                                        try {
                                            return (
                                                <tr key={i}>
                                                    <td>{cells[0].value}</td>
                                                    <td>{cells[1].value}</td>
                                                    <td>{cells[2].value}</td>
                                                </tr>
                                            );
                                        } catch (error) {
                                            return (
                                                <tr key={i} style={{ backgroundColor: '#f8d7da', textAlign: 'center' }}>
                                                    <td colSpan={3}>{ls.somethingIsWrongHere}</td>
                                                </tr>
                                            );
                                        }
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                    <small style={infoStyle}>
                        {rows.length}{' '}{ls.records}
                        <span hidden={issueCount === 0}>
                            {', '}{issueCount}{' '}{ls.xRecordsHaveIssues}
                        </span>
                    </small>
                </div>
            );
        }
        else {
            return '';
        }
    }
}

interface IProps {
    className?: string;

    sheet: IExcelSheet;
}

interface IState {
    ls: ILangSupport;
}
