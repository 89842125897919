import requester from "../shared/requester";
import { IResponse } from "../shared/models";

class HomeRequester {
    CheckBarcode = async (request: ICheckBarcodeRequest): Promise<ICheckBarcodeResponse> => {
        let resp = await requester.get<ICheckBarcodeResponse>(`/barcode/check/${request.Code.toString()}`, {});
        return resp.data;
    }
}
const homeRequester = new HomeRequester();
export { homeRequester };

export interface ICheckBarcodeRequest {
    Code: string;
}
export interface ICheckBarcodeResponse extends IResponse {
    Status: CheckBarcodeStatuses;
    Company: ICleanCompany;
}
export enum CheckBarcodeStatuses {
    NotFound,
    Suspicious,
    Original
}
export interface ICleanCompany {
    Id: string;

    Name: string;
    Email: string;
    Phone: string;

    NotFoundUrl: string;
    SuspiciousUrl: string;
    OriginalUrl: string;

    NotFoundImgUrl: string;
    SuspiciousImgUrl: string;
    OriginalImgUrl: string;
}