import * as React from 'react';
import { Card } from 'reactstrap';
import { ICompany } from '../company/Company.models';
import { ILangSupport, langHelper } from './Barcode.lang';
import { TabBarcode } from './tab/TabBarcode'

export class Barcode extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            ls: langHelper.Current
        };
    }

    componentDidMount = async (): Promise<void> => {
        langHelper.RegisterComponent(this);
    }
    componentWillUnmount = (): void => {
        langHelper.UnregisterComponent(this);
    }

    render() {
        let state = this.state;
        let ls = state.ls;

        let props = this.props;

        return (
            <div>
                <Card body>
                    <h5 style={{ position: 'absolute' }}>
                        {ls.title}{'  '}<i><small>{props.company.Name}</small></i>
                    </h5>
                    <TabBarcode company={props.company} />
                </Card>
            </div>
        );
    }
}

interface IProps {
    company: ICompany;
}

interface IState {
    ls: ILangSupport;
}
