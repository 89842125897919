import { Lang, MultiLangHelper } from "../shared/multi.lang";

export interface ILangSupport {
    lang: Lang;

    breadcrumbCompanies: string;
    breadcrumbBarcodes: string;
}

class TurkishSupport implements ILangSupport {
    lang = Lang.TR;

    breadcrumbCompanies = "Şirketler";
    breadcrumbBarcodes = "QR Kodlar";
}
const turkishSupport = new TurkishSupport();

class EnglishSupport implements ILangSupport {
    lang = Lang.EN;

    breadcrumbCompanies = "Companies";
    breadcrumbBarcodes = "QR Codes";
}
const englishSupport = new EnglishSupport();

const langHelper = new MultiLangHelper<ILangSupport>(turkishSupport, englishSupport);
export { langHelper }
