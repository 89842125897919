import * as React from 'react';
import { Lang } from '../shared/multi.lang';

export class LangSelector extends React.Component<IProps, IState> {

    private readonly langs: Lang[] = [];

    constructor(props: IProps) {
        super(props);
        this.langs = Lang.GetAll();
    }

    private readonly onLangChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
        let langCode = e.target.value;
        let lang = Lang.GetViaCode(langCode);
        Lang.Current = lang;
    }

    render() {
        return (
            <div>
                <select onChange={this.onLangChange}>
                    {
                        this.langs.map((lang, i) =>
                            <option key={i} value={lang.Code}>{lang.Code}</option>
                        )
                    }
                </select>

            </div>
        );
    }
}

interface IProps {
}

interface IState {
}
