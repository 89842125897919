import * as React from 'react';
import { Navbar, Container, NavbarBrand, NavbarToggler, Collapse, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { ILangSupport, langHelper } from './NavMenu.lang';
import { LangSelector } from '../lang/LangSelector';

export class NavMenu extends React.Component<IProps, IState> {
    static displayName = NavMenu.name;

    constructor(props: IProps) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            ls: langHelper.Current
        };
    }

    componentDidMount = (): void => {
        langHelper.RegisterComponent(this);
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        let ls = this.state.ls;
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">
                            <span className="bep-orange">BEP</span><span className="bep-black">ID</span>
                        </NavbarBrand>
                        <LangSelector />
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/admin/dashboard">{ls.adminPanel}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <a className="text-dark nav-link" href="http://www.bephologram.com/" target="_blank" rel="noreferrer" style={{ textDecoration: "none" }}>
                                        <span className="bep-orange">BEP</span><span className="bep-black">HOLOGRAM</span>
                                    </a>
                                </NavItem>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

interface IProps {
}

interface IState {
    ls: ILangSupport;
    collapsed: boolean;
}
