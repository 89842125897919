import * as React from 'react';
import { Button, Card, CardSubtitle, CardTitle, Col, Collapse, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Tooltip } from 'reactstrap';
import { ColorCodes } from '../../../shared/models';
import { FadeAlert, FadeAlertMessage } from '../../../shared/FadeAlert';
import { ILangSupport, langHelper } from './ListCompany.lang';
import { ICompany } from './../Company.models';
import { companyRequester, IListCompaniesRequest } from './../Company.requester';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faEdit, faEraser, faPhoneAlt, faQrcode, faQuestionCircle, faSearch } from '@fortawesome/free-solid-svg-icons';

export class ListCompany extends React.Component<IProps, IState> {

    searchInputRef = React.createRef<HTMLInputElement>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            ls: langHelper.Current,

            isHelpOn: false,

            querying: false,
            alert: FadeAlertMessage.default,

            search: '',
            page: 0,
            count: 20000,

            companyItems: []
        };
    }

    componentDidMount = async (): Promise<void> => {
        langHelper.RegisterComponent(this);
        this.list();
    }
    componentWillUnmount = (): void => {
        langHelper.UnregisterComponent(this);
    }

    private helpButtonClicked = (): void => {
        this.setState({ isHelpOn: !this.state.isHelpOn });
    }

    list = (): void => {
        let state = this.state;

        if (state.querying)
            return;

        this.setState({ querying: true }, async () => {
            try {
                let request: IListCompaniesRequest = { Search: state.search, Page: 0, Count: 20000 };
                let response = await companyRequester.List(request);

                this.setState({ querying: false }, () => {
                    if (!response.Success) {
                        let alertMessage = new FadeAlertMessage(response.Message, ColorCodes.warning);
                        this.setState({ alert: alertMessage });
                        return;
                    }

                    let companies = response.Companies;
                    let companyItems: ICompanyItem[] = [];
                    for (let i = 0; i < companies.length; i++) {
                        const company = companies[i];
                        companyItems.push({
                            company: company,
                            showEditTooltip: false,
                            showQrTooltip: false,
                            showDeleteTooltip: false
                        });
                    }

                    this.setState({ alert: FadeAlertMessage.default, companyItems: companyItems });
                });
            } catch (error) {
                let e = error as Error;
                let alertMessage = new FadeAlertMessage(e.message, ColorCodes.danger);
                this.setState({ alert: alertMessage, querying: false });
            }
            finally {
                let searchInputElement = this.searchInputRef.current;
                if (searchInputElement === null)
                    return;
                searchInputElement.focus();
            }
        });
    }

    private searchTimeout: NodeJS.Timeout | null = null;
    private clearSearchTimeout = (): void => {
        if (this.searchTimeout === null)
            return;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
    };
    private searchChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let newValue = e.target.value;

        this.setState({ search: newValue }, () => {
            this.clearSearchTimeout();
            this.searchTimeout = setTimeout(() => {
                this.list();
                this.clearSearchTimeout();
            }, 1000);
        });
    }
    private searchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key !== 'Enter')
            return;

        this.clearSearchTimeout();
        this.list();
    }

    render() {
        let state = this.state;
        let ls = state.ls;
        let companyItems = state.companyItems;

        let props = this.props;

        return (
            <div hidden={props.hidden}>
                <Card body>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <Button className="btn-sm" color={ColorCodes.dark} onClick={this.helpButtonClicked} style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </Button>
                            <Collapse isOpen={state.isHelpOn}>
                                <Card body>
                                    <ul>
                                        <li>{ls.help_startTyping}</li>
                                        <li>{ls.help_justPressEnter}</li>
                                        <li>{ls.help_barcodeMenu}</li>
                                    </ul>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input innerRef={this.searchInputRef} type="text" placeholder={ls.search} value={state.search} disabled={state.querying}
                                        onChange={this.searchChanged} onKeyDown={this.searchKeyDown} />
                                </InputGroup>
                            </FormGroup>
                            <FadeAlert alert={state.alert} tag="span" className="mt-3" />
                        </Col>
                    </Row>
                    <Row>
                        {companyItems.map((companyItem, i) => {
                            let company = companyItem.company;
                            let hasEmail = company.Email !== null && company.Email.length > 0;
                            let hasPhone = company.Phone !== null && company.Phone.length > 0;

                            let editTarget = `edit_${company.Id}`;
                            let toggleEditTooltip = () => {
                                companyItem.showEditTooltip = !companyItem.showEditTooltip;
                                this.setState({ companyItems: companyItems });
                            }

                            let qrTarget = `qr_${company.Id}`;
                            let toggleQrTooltip = () => {
                                companyItem.showQrTooltip = !companyItem.showQrTooltip;
                                this.setState({ companyItems: companyItems });
                            }

                            let deleteTarget = `delete_${company.Id}`;
                            let toggleDeleteTooltip = () => {
                                companyItem.showDeleteTooltip = !companyItem.showDeleteTooltip;
                                this.setState({ companyItems: companyItems });
                            }

                            let props = this.props;

                            let onEditClick = () => {
                                let handler = props.onEditPress;
                                if (handler !== undefined)
                                    handler({ company: company })
                            }
                            let onQrClick = () => {
                                let handler = props.onQrPress;
                                if (handler !== undefined)
                                    handler({ company: company })
                            }
                            let onDeleteClick = () => {
                                let handler = props.onDeletePress;
                                if (handler !== undefined)
                                    handler({ company: company })
                            }

                            return (
                                <Col sm="6" md="5" lg="4" key={i} style={{ paddingBottom: '2%', minHeight: '100%', textAlign: 'center' }}>
                                    <Card body>
                                        <CardTitle tag="h4" className="noOverflowJustEllipsis">{company.Name}</CardTitle>
                                        <CardSubtitle tag="h6" className="mb-2 text-muted">
                                            {
                                                hasEmail
                                                    ?
                                                    <div className="noOverflowJustEllipsis">
                                                        <FontAwesomeIcon icon={faAt} />&nbsp;
                                                        <a href={`mailto:${company.Email}`}>{company.Email}</a>
                                                    </div>
                                                    :
                                                    <span>&nbsp;</span>
                                            }
                                        </CardSubtitle>
                                        <CardSubtitle tag="h6" className="mb-2 text-muted">
                                            {
                                                hasPhone
                                                    ?
                                                    <div className="noOverflowJustEllipsis">
                                                        <FontAwesomeIcon icon={faPhoneAlt} />&nbsp;
                                                        <a href={`tel:${company.Phone}`}>{company.Phone}</a>
                                                    </div>
                                                    : <span>&nbsp;</span>
                                            }
                                        </CardSubtitle>
                                        <Row style={{ margin: 0 }}>
                                            <Col sm={4} md={4} lg={4}>
                                                <Button id={editTarget} className="btn-bep btn-sm" onClick={onEditClick}>
                                                    <FontAwesomeIcon icon={faEdit} />
                                                </Button>
                                                <Tooltip placement="bottom" isOpen={companyItem.showEditTooltip} target={editTarget} toggle={toggleEditTooltip} >
                                                    {ls.edit}
                                                </Tooltip>
                                            </Col>
                                            <Col sm={4} md={4} lg={4}>
                                                <Button id={qrTarget} className="btn-bep btn-sm" onClick={onQrClick} >
                                                    <FontAwesomeIcon icon={faQrcode} />
                                                </Button>
                                                <Tooltip placement="bottom" isOpen={companyItem.showQrTooltip} target={qrTarget} toggle={toggleQrTooltip} >
                                                    {ls.qr}
                                                </Tooltip>
                                            </Col>
                                            <Col sm={4} md={4} lg={4}>
                                                <Button id={deleteTarget} className="btn-bep btn-sm" onClick={onDeleteClick}>
                                                    <FontAwesomeIcon icon={faEraser} />
                                                </Button>
                                                <Tooltip placement="bottom" isOpen={companyItem.showDeleteTooltip} target={deleteTarget} toggle={toggleDeleteTooltip} >
                                                    {ls.delete}
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                </Card>
            </div>
        );
    }
}

interface IProps {
    hidden?: boolean;
    onEditPress?: { (e: ICompanyActionEvent): void }
    onQrPress?: { (e: ICompanyActionEvent): void }
    onDeletePress?: { (e: ICompanyActionEvent): void }
}

interface IState {
    ls: ILangSupport;

    isHelpOn: boolean;

    querying: boolean;
    alert: FadeAlertMessage;

    search: string;
    page: number;
    count: number;

    companyItems: ICompanyItem[];
}

interface ICompanyItem {
    company: ICompany;

    showEditTooltip: boolean;
    showQrTooltip: boolean;
    showDeleteTooltip: boolean;
}

export interface ICompanyActionEvent {
    company: ICompany
}
