import * as React from 'react';
import { Button, Card, CardBody, Col, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { ILangSupport, langHelper } from './BarcodeCheck.lang';
import { CheckBarcodeStatuses, homeRequester, ICheckBarcodeRequest, ICheckBarcodeResponse } from '../Home.requester';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faPhoneAlt, faSearch } from '@fortawesome/free-solid-svg-icons';

enum Modes { check, checkResult }

export class BarcodeCheck extends React.Component<IProps, IState> {
    static displayName = BarcodeCheck.name;

    constructor(props: IProps) {
        super(props);

        let urlSerialNumber = this.getSerialNumberFromUrl();

        this.state = {
            ls: langHelper.Current,

            mode: Modes.check,
            serialNumber: urlSerialNumber ? urlSerialNumber : '',
            submitting: false,

            response: undefined
        };
    }

    componentDidMount = (): void => {
        langHelper.RegisterComponent(this);
        if (this.state.serialNumber)
            this.check();
    }
    componentWillUnmount = (): void => {
        langHelper.UnregisterComponent(this);
    }

    private getSerialNumberFromUrl = (): string => {
        let path = window.location.pathname;
        return path.substring(path.lastIndexOf('/') + 1);
    }

    private onCheckClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        this.check();
    }

    check = (): void => {
        let state = this.state;
        if (!state.serialNumber)
            return;
        this.setState({ response: undefined, mode: Modes.check }, async () => {
            try {
                let request: ICheckBarcodeRequest = { Code: state.serialNumber };
                let response = await homeRequester.CheckBarcode(request);
            //    console.log(response.Company.OriginalUrl)

                if (!response.Success) {
                    //todo
                    console.warn(response);
                    this.setState({ submitting: false });
                }
                else
                    this.setState({ submitting: false, mode: Modes.checkResult, response: response });

            } catch (error) {
                //todo
                console.error(error);
            }
        });
    }

    onSerialNumberChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let newValue = e.target.value;
        this.setState({ serialNumber: newValue });
    }
    private checkKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key !== 'Enter')
            return;
        this.check();
    }

    private renderResponse = (): React.ReactElement | string => {
        let state = this.state;

        if (state.mode !== Modes.checkResult)
            return '';

        let response = state.response;
        if (!response)
            return '';

        let ls = state.ls;

        let infoElementClassName = "d-flex justify-content-center";

        if (response.Status === CheckBarcodeStatuses.NotFound)
            return (
                <CardBody>
                    <h5 className={infoElementClassName}>{ls.notFound}</h5>
                    <img src={'imgs/notfound.png'} alt="notfound" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                </CardBody>
            );

        let company = response.Company;

        let url = '';
        let imgUrl = '';
        let alt = 'img';
        let companyName = '';
        let companyEmail = '';
        let companyPhone = '';
        let mailSubject = '';
        let mailBody = '';
        if (response.Status === CheckBarcodeStatuses.Suspicious) {
            url = company.SuspiciousUrl;
            imgUrl = company.SuspiciousImgUrl;
            alt = 'original';
            companyName = company.Name;
            companyEmail = company.Email;
            companyPhone = company.Phone;
            mailSubject = ls.mailSubjectSuspicious;
            mailBody = ls.mailBodySuspicious;
        }
        else if (response.Status === CheckBarcodeStatuses.Original) {
            url = company.OriginalUrl;
            imgUrl = company.OriginalImgUrl;
            alt = 'suspicious';
            companyName = company.Name;
            companyEmail = company.Email;
            companyPhone = company.Phone;
            mailSubject = ls.mailSubjectOriginal;
            mailBody = ls.mailBodyOriginal;
        }

        mailSubject = mailSubject.replaceAll('{0}', state.serialNumber);
        mailBody = mailBody.replaceAll('{0}', state.serialNumber);

        let infoMailSize = !companyPhone || !companyEmail ? 12 : 8;
        let infoPhoneSize = !companyPhone || !companyEmail ? 12 : 4;

        let infoMailClass = !companyPhone || !companyEmail ? "d-flex justify-content-center" : "d-flex justify-content-start";
        let infoPhoneClass = !companyPhone || !companyEmail ? "d-flex justify-content-center" : "d-flex justify-content-end";

        //let imageStyle:React.CSSProperties = { maxWidth: "100%", maxHeight: "100%" };
        let imageStyle: React.CSSProperties = { width: "600px", height: "550px" };

        return (
            <CardBody style={{ paddingTop: 0 }}>
                <a href={url} target="_blank" className={infoElementClassName}>
                    <img src={imgUrl} alt={alt} style={imageStyle} />
                    
                </a>
                <Row>
                    {
                        companyEmail &&
                        <Col sm={infoMailSize} md={infoMailSize} lg={infoMailSize} className={infoMailClass}>
                            <a href={`mailto:${companyEmail}?subject=${mailSubject}&body=${mailBody}`}>{companyEmail}</a>
                        </Col>
                    }
                    {
                        companyPhone &&
                        <Col sm={infoPhoneSize} md={infoPhoneSize} lg={infoPhoneSize} className={infoPhoneClass}>
                            <a href={`tel:${companyPhone}`}>{companyPhone}</a>
                        </Col>
                    }
                </Row>
            </CardBody>
        );
    }

    private renderCheck = (): React.ReactElement => {
        let state = this.state;
        let ls = state.ls;

        return (
            <Row className="d-flex justify-content-center">
                <Card style={{ maxWidth: "70%", maxHeight: "70%" }}>
                    <CardBody>
                        <Row className="d-flex justify-content-center">
                            <img src="imgs/logo.png" alt="logo" style={{ width: '210px' }} />
                        </Row>
                    </CardBody>
                    <CardBody>
                        <Row className="d-flex justify-content-center">
                            <InputGroup>
                                <Input type="text" placeholder={ls.serialNumber} value={state.serialNumber} onChange={this.onSerialNumberChange} onKeyDown={this.checkKeyDown} disabled={state.submitting} />
                                <InputGroupAddon addonType="append">
                                    <Button className="btn-bep" onClick={this.onCheckClick}>
                                        <FontAwesomeIcon icon={faSearch} />{' '}
                                        {ls.check}
                                    </Button>
                                </InputGroupAddon>
                            </InputGroup>
                        </Row>
                    </CardBody>
                    {this.renderResponse()}
                </Card>
            </Row>
        );
    }

    private renderBephologramInfo = (): React.ReactElement => {
        let state = this.state;
        let ls = state.ls;

        let phoneStr = '0850 209 1901';
        let phoneNum = phoneStr.replaceAll(" ", "");
        let email = 'hologram@bephologram.com';

        return (
            <Row className="d-flex justify-content-center" style={{ paddingTop: '30%' }}>
                <Card style={{ width: '100%', border: 'none' }}>
                    <CardBody>
                        <Row className="d-flex justify-content-center">
                            <a href="http://www.bephologram.com/">
                                <img src="imgs/bephologram.png" alt="logo" style={{ width: '110px' }} />
                            </a>
                        </Row>
                        <Row className="d-flex justify-content-center" style={{ marginBottom: 0 }}>
                            <small>{ls.bepIdIsAServiceOfBepHologram}</small>
                        </Row>
                        <Row className="d-flex justify-content-center" style={{ margin: 0 }}>
                            <small><b>{ls.forMoreInfo}</b></small>
                        </Row>
                        <Row className="d-flex justify-content-center" style={{ marginTop: 0 }}>
                            <small>
                                <a href={`tel:${phoneNum}`}>{phoneStr}</a> - <a href={`mailto:${email}`}>{email}</a></small>
                        </Row>
                    </CardBody>
                </Card>
            </Row>
        );
    }

    render() {
        let state = this.state;
        let ls = state.ls;

        return (
            <div>
                {this.renderCheck()}
                {this.renderBephologramInfo()}
            </div>
        );
    }
}

interface IProps { }

interface IState {
    ls: ILangSupport;

    mode: Modes;
    serialNumber: string;
    submitting: boolean;

    response?: ICheckBarcodeResponse;
}

//<a href={"https://www.google.com.tr/?hl=tr"} className={infoElementClassName}>
//    <img src={imgUrl} alt={alt} style={imageStyle} />
//</a>