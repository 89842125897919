import { Lang, MultiLangHelper } from "../../../shared/multi.lang";

export interface ILangSupport {
    lang: Lang;

    help_default: string;

    hashtags: string;
    save: string;

    'BarcodeController.EditBarcodeSet.request': string;
    'BarcodeController.EditBarcodeSet.Id': string;
    'BarcodeController.EditBarcodeSet.barcodeSet': string;
    'BarcodeController.EditBarcodeSet': string;
}

class TurkishSupport implements ILangSupport {
    lang = Lang.TR;

    help_default = "aşağıdaki formu kullanarak değişikliklerinizi uygulayabilirsiniz";

    hashtags = "hashtag'ler (her hashtag'den sonra 'enter'a basın)";
    save = "kaydet";

    'BarcodeController.EditBarcodeSet.request' = "yapmaya çalıştığınız istek sunucuya ulaşmadı";
    'BarcodeController.EditBarcodeSet.Id' = "id bilgisi eksik";
    'BarcodeController.EditBarcodeSet.barcodeSet' = "düzenlemek istediğiniz aktarım kaydı silinmiş olabilir";
    'BarcodeController.EditBarcodeSet' = "aktarım kaydı başarıyla güncellendi";
}
const turkishSupport = new TurkishSupport();

class EnglishSupport implements ILangSupport {
    lang = Lang.EN;

    help_default = "you may apply your changes using the form below";

    hashtags = "hashtags (press 'enter' after each hastag)";
    save = "save";

    'BarcodeController.EditBarcodeSet.request' = "your request couldn't make it's way to the server";
    'BarcodeController.EditBarcodeSet.Id' = "id information is missing";
    'BarcodeController.EditBarcodeSet.barcodeSet' = "the import record you're trying to edit might have been deleted";
    'BarcodeController.EditBarcodeSet' = "import record was successfully edited";
}
const englishSupport = new EnglishSupport();

const langHelper = new MultiLangHelper<ILangSupport>(turkishSupport, englishSupport);
export { langHelper }
