import { faFileImport, faHashtag, faQuestionCircle, faTags } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button, Card, Col, Collapse, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { ILangSupport, langHelper } from './ImportBarcode.lang';
import { ColorCodes } from '../../../shared/models';
import { ICompany } from './../../company/Company.models';
import { FileUpload, IOnChangeEvent } from '../../../shared/FileUpload';
import { FadeAlert, FadeAlertMessage } from '../../../shared/FadeAlert';
import { barcodeRequester, IImportBarcodeRequest } from '../Barcode.requester';
import { ExcelReader, IExcelSheet } from '../../../shared/ExcelHelpers';
import { Tags } from '../../../shared/Tags';
import { ExcelPreview } from '../../../excel/ExcelPreview';

export class ImportBarcode extends React.Component<IProps, IState> {

    fileUploadRef = React.createRef<FileUpload>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            ls: langHelper.Current,

            tags: [],

            isHelpOn: false,
            alert: FadeAlertMessage.default,
            fileName: '',

            submitting: false,

            excelPreviewSheet: { rows: [] }
        };
    }

    componentDidMount = async (): Promise<void> => {
        langHelper.RegisterComponent(this);
    }
    componentWillUnmount = (): void => {
        langHelper.UnregisterComponent(this);
    }

    private helpButtonClicked = (): void => {
        this.setState({ isHelpOn: !this.state.isHelpOn });
    }

    private onFileChange = (e: IOnChangeEvent): void => {
        let fileDetails = e.fileDetails;

        if (fileDetails === null || fileDetails.length === 0) {
            this.setState({ fileName: '', excelPreviewSheet: { rows: [] } });
            return;
        }

        let fileDetail = fileDetails[0];
        this.setState({ fileName: fileDetail.file.name });

        let excelReader = new ExcelReader();
        excelReader.read(e.fileDetails[0].file).then((sheets) => {
            this.setState({ excelPreviewSheet: sheets[0] });
        });
    }

    private getFile = (ref: React.RefObject<FileUpload>): File | null => {
        let fileUpload = ref.current;
        if (fileUpload !== null) {
            let fileList = fileUpload.fileList;
            if (fileList !== null) {
                return fileList[0];
            }
        }
        return null;
    }

    private onTagsChange = (tags: string[]): void => {
        this.setState({ tags: tags });
    };

    submit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();

        let props = this.props;
        let state = this.state;
        let ls = state.ls;

        this.setState({ submitting: true, alert: FadeAlertMessage.default }, async () => {
            try {
                let request: IImportBarcodeRequest = {
                    CompanyId: props.company.Id,
                    File: this.getFile(this.fileUploadRef),
                    Tags: state.tags
                    
                };
                let response = await barcodeRequester.Import(request);
                let message = langHelper.GetViaKey(ls, response.Message);
                let color = response.Success ? ColorCodes.success : ColorCodes.warning;

                this.setState({ submitting: false, alert: new FadeAlertMessage(message, color) }, () => {
                    props.onNewImport({});
                });
            } catch (error) {
                let e = error as Error;
                let alertMessage = new FadeAlertMessage(e.message, ColorCodes.danger);
                this.setState({ alert: alertMessage, submitting: false });
            }
        });
       
    }

    render() {
        let state = this.state;
        let ls = state.ls;

        let excelPreviewSheet = state.excelPreviewSheet;

        let fileUploadAccept = ".xlsx";

        return (
            <div>
                <Card body>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <Button className="btn-sm" color={ColorCodes.dark} onClick={this.helpButtonClicked} style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </Button>
                            <Collapse isOpen={state.isHelpOn}>
                                <Card body>
                                    <ul>
                                        <li>{ls.help_previewWillBeShown}</li>
                                        <li>{ls.help_inCaseOfAProblem}</li>
                                        <li>{ls.help_pleaseDoCheck}</li>
                                        <li>{ls.help_editTheFile}</li>
                                        <li>{ls.help_activeByDefault}</li>
                                        <li>{ls.help_youMayDisableIt}</li>
                                    </ul>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} md={6} lg={6}>
                            <InputGroup >
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText className="required-input">
                                        <FontAwesomeIcon icon={faFileImport} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <FileUpload ref={this.fileUploadRef} className="btn-sm" accept={fileUploadAccept}
                                    multiple={false} disabled={state.submitting}
                                    onChange={this.onFileChange}>
                                    {ls.selectFile + '*'}
                                </FileUpload>
                            </InputGroup>
                        </Col>
                        <Col sm={6} md={6} lg={6}>
                            <span>
                                {state.fileName}
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={faHashtag} />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Tags placeHolder={ls.hashtags} value={state.tags} onChange={this.onTagsChange} />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row hidden={excelPreviewSheet.rows.length === 0}>
                        <ExcelPreview sheet={excelPreviewSheet} />
                    </Row>
                    <Row hidden={excelPreviewSheet.rows.length === 0}>
                        <Col sm={12} md={12} lg={12}>
                            <FadeAlert alert={state.alert} tag="span" className="mt-3" />
                            <div className="d-flex justify-content-center">
                                <Button className="btn-sm btn-bep" disabled={state.submitting} onClick={this.submit}>
                                    <FontAwesomeIcon icon={faFileImport} />&nbsp;
                                    {ls.import}
                                   
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

interface IProps {
    company: ICompany;
    onNewImport: { (e: INewImportEvent): void }
}

interface IState {
    ls: ILangSupport;

    tags: string[];

    isHelpOn: boolean;
    alert: FadeAlertMessage;
    fileName: string;

    submitting: boolean;

    excelPreviewSheet: IExcelSheet;
}

export interface INewImportEvent {

}
