import { Lang, MultiLangHelper } from "../shared/multi.lang";

export interface ILangSupport {
    lang: Lang;

    title: string;

    serialNumber: string;
    check: string;
}

class TurkishSupport implements ILangSupport {
    lang = Lang.TR;

    title = "BepHologram'a hoşgeldiniz";

    serialNumber = "seri numarası";
    check = "sorgula";
}
const turkishSupport = new TurkishSupport();

class EnglishSupport implements ILangSupport {
    lang = Lang.EN;

    title = "Welcome to BepHologram";

    serialNumber = "serial number";
    check = "check";
}
const englishSupport = new EnglishSupport();

const langHelper = new MultiLangHelper<ILangSupport>(turkishSupport, englishSupport);
export { langHelper }
