import * as React from 'react';
import { ILangSupport, langHelper } from './TabBarcode.lang';
import { ImportBarcode, INewImportEvent } from './../import/ImportBarcode';
import { IBarcodeSetActionEvent, ListBarcodeSet } from './../list/ListBarcodeSet';
import { ICompany } from '../../company/Company.models';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faFileImport, faEdit } from '@fortawesome/free-solid-svg-icons';
import { IBarcodeSet } from '../Barcode.models';
import { EditBarcodeSet, IOnSubmitEvent } from '../edit/EditBarcodeSet';

enum Tabs { new = 'new', list = 'list', edit = 'edit' }

export class TabBarcode extends React.Component<IProps, IState> {

    private readonly listBarcodeSetRef = React.createRef<ListBarcodeSet>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            ls: langHelper.Current,

            activeTab: Tabs.new,
            editModeModel: undefined
        };
    }

    componentDidMount = async (): Promise<void> => {
        langHelper.RegisterComponent(this);
    }
    componentWillUnmount = (): void => {
        langHelper.UnregisterComponent(this);
    }

    private onEditPress = (e: IBarcodeSetActionEvent): void => {
        this.setState({ editModeModel: { barcodeSet: e.barcodeSet } }, () => {
            this.toggle(Tabs.edit);
        });
    }
    private onEdit = (e: IOnSubmitEvent): void => {
        this.reloadBarcodeSets();
        this.toggle(Tabs.list);
    }
    private reloadBarcodeSets = (): void => {
        let listBarcodeSet = this.listBarcodeSetRef.current;
        if (listBarcodeSet === null)
            return;
        listBarcodeSet.list();
    }

    private toggle = (tab: Tabs) => {
        let state = this.state;

        if (state.activeTab === tab)
            return;

        let editModeModel = tab === Tabs.edit ? state.editModeModel : undefined;
        this.setState({ activeTab: tab, editModeModel: editModeModel });
    }
    private getClassName = (tab: Tabs) => {
        return this.state.activeTab === tab ? 'active' : '';
    }

    private onNewImport = (e: INewImportEvent): void => {
        let listBarcodeSet = this.listBarcodeSetRef.current;
        if (!listBarcodeSet)
            return;
        listBarcodeSet.list();
    }

    render() {
        let state = this.state;
        let ls = state.ls;

        let props = this.props;

        let activeTab = state.activeTab;

        return (
            <div>
                <Nav tabs>
                    <NavItem style={{ cursor: 'pointer', marginLeft: 'auto' }}>
                        <NavLink className={this.getClassName(Tabs.new)}
                            onClick={() => { this.toggle(Tabs.new); }}>
                            <FontAwesomeIcon icon={faFileImport} />&nbsp;
                            {ls.importFile}
                        </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: 'pointer' }}>
                        <NavLink className={this.getClassName(Tabs.list)}
                            onClick={() => { this.toggle(Tabs.list); }}>
                            <FontAwesomeIcon icon={faHistory} />&nbsp;
                            {ls.importHistory}
                        </NavLink>
                    </NavItem>
                    {
                        state.editModeModel &&
                        <NavItem style={{ cursor: 'pointer' }}>
                            <NavLink className={this.getClassName(Tabs.edit)}
                                onClick={() => { this.toggle(Tabs.edit); }}>
                                <FontAwesomeIcon icon={faEdit} />&nbsp;
                                {ls.importEdit}
                            </NavLink>
                        </NavItem>
                    }
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={Tabs.new}>
                        <ImportBarcode company={props.company} onNewImport={this.onNewImport} />
                    </TabPane>
                    <TabPane tabId={Tabs.list}>
                        <ListBarcodeSet ref={this.listBarcodeSetRef} company={props.company} onEditPress={this.onEditPress} />
                    </TabPane>
                    {
                        state.editModeModel &&
                        <TabPane tabId={Tabs.edit}>
                            <EditBarcodeSet barcodeSet={state.editModeModel.barcodeSet} onSubmit={this.onEdit} />
                        </TabPane>
                    }
                </TabContent>
            </div>
        );
    }
}

interface IProps {
    company: ICompany
}

interface IEditModeModel {
    barcodeSet: IBarcodeSet;
}

interface IState {
    ls: ILangSupport;

    activeTab: string;
    editModeModel?: IEditModeModel
}
