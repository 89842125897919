import * as React from 'react';
import { Button } from 'reactstrap';

export class FileUpload extends React.Component<IProps, IState>  {
    private readonly fileInputRef = React.createRef<HTMLInputElement>();

    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    get fileList(): FileList | null {
        let fileInput = this.fileInputRef.current;
        if (fileInput === null)
            return null;
        return fileInput.files;
    }

    clear = (): void => {
        let fileInput = this.fileInputRef.current;
        if (fileInput === null)
            return;
        fileInput.value = '';
    }

    private getFileFromInput = (file: File): Promise<any> => {
        return new Promise(function (resolve, reject) {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => resolve(reader.result);
            reader.readAsBinaryString(file); // here the file can be read in different way Text, DataUrl, ArrayBuffer
        });
    }

    private handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        event.persist();
        let files = event.target.files;
        if (files === null)
            return;

        let props = this.props;

        let fileDetails: IFileDetail[] = [];
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            try {
                let binary = await this.getFileFromInput(file);
                fileDetails.push({ binary: binary, file: file });
            } catch (e) {
                console.log('Error during upload', e);
                event.target.value = ''; // to allow upload of same file if error occurs

                if (props.onError === undefined)
                    throw e;
                props.onError(e);
            }
        }

        if (props.onChange !== undefined)
            props.onChange({ fileDetails: fileDetails });
    }

    private onClick = (e: any): void => {
        e.stopPropagation();
        let fileInput = this.fileInputRef.current;
        if (fileInput === undefined || fileInput === null)
            return;
        fileInput.click();
    }

    render() {
        let props = this.props;
        return (
            <div>
                <Button className={props.className} onClick={this.onClick} disabled={props.disabled}>
                    {props.children}
                </Button>
                <input ref={this.fileInputRef}
                    accept={props.accept}
                    style={{ display: 'none' }}
                    multiple={props.multiple}
                    type="file"
                    name={props.name}
                    onChange={this.handleFileChange}
                />
            </div>
        );
    }
}

interface IProps {
    className?: string;
    name?: string;
    accept?: string;
    multiple?: boolean;
    disabled?: boolean;
    onChange?: { (e: IOnChangeEvent): void }
    onError?: { (e: any): void }
}

interface IState { }

export interface IFileDetail {
    binary: string;
    file: File;
}

export interface IOnChangeEvent {
    fileDetails: IFileDetail[];
}