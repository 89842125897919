import * as React from 'react';
import { Button, Card, CardSubtitle, Col, Collapse, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Tooltip } from 'reactstrap';
import { ColorCodes } from '../../../shared/models';
import { FadeAlert, FadeAlertMessage } from '../../../shared/FadeAlert';
import { ILangSupport, langHelper } from './ListBarcodeSet.lang';
import { BarcodeSetState, BarcodeSetStatus, IBarcodeSet } from './../Barcode.models';
import { barcodeRequester, IListBarcodeSetsRequest, ISetBarcodeSetStatusRequest } from './../Barcode.requester';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEdit, faExclamationTriangle, faQuestionCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { ICompany } from '../../company/Company.models';

export class ListBarcodeSet extends React.Component<IProps, IState> {

    searchInputRef = React.createRef<HTMLInputElement>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            ls: langHelper.Current,

            isHelpOn: false,

            querying: false,
            alert: FadeAlertMessage.default,

            search: '',
            page: 0,
            count: 20000,

            barcodeSetItems: []
        };
    }

    componentDidMount = async (): Promise<void> => {
        langHelper.RegisterComponent(this);
        this.list();
    }
    componentWillUnmount = (): void => {
        langHelper.UnregisterComponent(this);
    }

    private helpButtonClicked = (): void => {
        this.setState({ isHelpOn: !this.state.isHelpOn });
    }

    list = (): void => {
        let props = this.props;
        let state = this.state;

        if (state.querying)
            return;

        this.setState({ querying: true }, async () => {
            try {
                let request: IListBarcodeSetsRequest = { Search: state.search, Page: 0, Count: 20000, CompanyId: props.company.Id };
                let response = await barcodeRequester.SetList(request);

                this.setState({ querying: false }, () => {
                    if (!response.Success) {
                        let alertMessage = new FadeAlertMessage(response.Message, ColorCodes.warning);
                        this.setState({ alert: alertMessage });
                        return;
                    }

                    let barcodeSets = response.BarcodeSets;
                    let barcodeSetItems: IBarcodeSetItem[] = [];
                    for (let i = 0; i < barcodeSets.length; i++) {
                        const barcodeSet = barcodeSets[i];
                        barcodeSetItems.push({
                            barcodeSet: barcodeSet,
                            showActiveTooltip: false,
                            showPassiveTooltip: false,
                            showFailedTooltip: false,
                            showEditTooltip: false
                        });
                    }

                    this.setState({ alert: FadeAlertMessage.default, barcodeSetItems: barcodeSetItems });
                });
            } catch (error) {
                let e = error as Error;
                let alertMessage = new FadeAlertMessage(e.message, ColorCodes.danger);
                this.setState({ alert: alertMessage, querying: false });
            }
            finally {
                let searchInputElement = this.searchInputRef.current;
                if (searchInputElement === null)
                    return;
                searchInputElement.focus();
            }
        });
    }

    private searchTimeout: NodeJS.Timeout | null = null;
    private clearSearchTimeout = (): void => {
        if (this.searchTimeout === null)
            return;
        clearTimeout(this.searchTimeout);
        this.searchTimeout = null;
    };
    private searchChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let newValue = e.target.value;

        this.setState({ search: newValue }, () => {
            this.clearSearchTimeout();
            this.searchTimeout = setTimeout(() => {
                this.list();
                this.clearSearchTimeout();
            }, 1000);
        });
    }
    private searchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key !== 'Enter')
            return;

        this.clearSearchTimeout();
        this.list();
    }

    private onHashtagClick = (tag: string): void => {
        if (!tag)
            return;

        let state = this.state;
        if (state.querying)
            return;

        let search = state.search;

        if (search.length > 0)
            search += ' ';

        search += '#' + tag;

        this.setState({ search: search }, this.list);
    }

    render() {
        let props = this.props;

        let state = this.state;
        let ls = state.ls;
        let barcodeSetItems = state.barcodeSetItems;

        return (
            <div hidden={props.hidden}>
                <Card body>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <Button className="btn-sm" color={ColorCodes.dark} onClick={this.helpButtonClicked} style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </Button>
                            <Collapse isOpen={state.isHelpOn}>
                                <Card body>
                                    <ul>
                                        <li>{ls.help_startTyping}</li>
                                        <li>{ls.help_justPressEnter}</li>
                                        <li>
                                            {ls.help_searchProperties}
                                            <ul>
                                                <li>{ls.help_searchProperties_1}</li>
                                                <li>{ls.help_searchProperties_2}</li>
                                                <ul>
                                                    <li>{ls.help_searchProperties_2_1}</li>
                                                    <li>{ls.help_searchProperties_2_2}</li>
                                                </ul>
                                                <li>{ls.help_searchProperties_3}</li>
                                                <ul>
                                                    <li>{ls.help_searchProperties_3_1}</li>
                                                    <li>{ls.help_searchProperties_3_2}</li>
                                                </ul>
                                                <li>{ls.help_searchProperties_4}</li>
                                                <li>{ls.help_searchProperties_5}</li>
                                                <ul>
                                                    <li>{ls.help_searchProperties_5_1}</li>
                                                    <li>{ls.help_searchProperties_5_2}</li>
                                                    <li>{ls.help_searchProperties_5_3}</li>
                                                    <li>{ls.help_searchProperties_5_4}</li>
                                                    <li>{ls.help_searchProperties_5_5}</li>
                                                </ul>
                                            </ul>
                                        </li>
                                        <li>{ls.help_downloadFile}</li>
                                        <li>{ls.help_activePassive}</li>
                                        <li>{ls.help_duplications}</li>
                                    </ul>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faSearch} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input innerRef={this.searchInputRef} type="text" placeholder={ls.search} value={state.search} disabled={state.querying}
                                        onChange={this.searchChanged} onKeyDown={this.searchKeyDown} />
                                </InputGroup>
                            </FormGroup>
                            <FadeAlert alert={state.alert} tag="span" className="mt-3" />
                        </Col>
                    </Row>
                    <Row>
                        {barcodeSetItems.map((barcodeSetItem, i) => {
                            let barcodeSet = barcodeSetItem.barcodeSet;

                            let activeTarget = `active_${barcodeSet.Id}`;
                            let toggleActiveTooltip = () => {
                                barcodeSetItem.showActiveTooltip = !barcodeSetItem.showActiveTooltip;
                                this.setState({ barcodeSetItems: barcodeSetItems });
                            }

                            let passiveTarget = `passive_${barcodeSet.Id}`;
                            let togglePassiveTooltip = () => {
                                barcodeSetItem.showPassiveTooltip = !barcodeSetItem.showPassiveTooltip;
                                this.setState({ barcodeSetItems: barcodeSetItems });
                            }

                            let failedTarget = `failed_${barcodeSet.Id}`;
                            let toggleFailedTooltip = () => {
                                barcodeSetItem.showFailedTooltip = !barcodeSetItem.showFailedTooltip;
                                this.setState({ barcodeSetItems: barcodeSetItems });
                            }

                            let editTarget = `edit_${barcodeSet.Id}`;
                            let toggleEditTooltip = () => {
                                barcodeSetItem.showEditTooltip = !barcodeSetItem.showEditTooltip;
                                this.setState({ barcodeSetItems: barcodeSetItems });
                            }

                            let setBarcodeSetStatus = async (status: BarcodeSetStatus): Promise<void> => {
                                try {
                                    let request: ISetBarcodeSetStatusRequest = {
                                        Id: barcodeSet.Id,
                                        BarcodeSetStatus: status
                                    };
                                    let response = await barcodeRequester.SetBarcodeSetStatus(request);
                                    if (!response.Success) {
                                        console.warn(response.Message);
                                        return;
                                    }
                                    barcodeSet.Status = status;
                                    this.setState({ barcodeSetItems: barcodeSetItems });
                                } catch (error) {
                                    console.error(error);
                                }
                            }

                            let onActiveClick = () => {
                                setBarcodeSetStatus(BarcodeSetStatus.Passive);
                            }
                            let onPassiveClick = () => {
                                setBarcodeSetStatus(BarcodeSetStatus.Active);
                            }
                            let onEditClick = () => {
                                let handler = props.onEditPress;
                                if (handler !== undefined)
                                    handler({ barcodeSet: barcodeSet })
                            }

                            let tags = barcodeSet.Tags ?? [];

                            return (
                                <Col sm={6} md={5} lg={4} key={i} style={{ paddingBottom: '2%', minHeight: '100%', textAlign: 'center' }}>
                                    <Card body>
                                        <CardSubtitle tag="h6" className="noOverflowJustEllipsis">
                                            <a href={`/barcode/set/download/${barcodeSet.Id}`}>
                                                {barcodeSet.UploadedFileName}
                                            </a>
                                        </CardSubtitle>
                                        <CardSubtitle tag="h6" className="mb-2 text-muted" style={{ marginTop: '1%' }}>
                                            {barcodeSet.BarcodeCount} {ls.records}
                                        </CardSubtitle>
                                        <CardSubtitle tag="h6" className="mb-2 text-muted">
                                            {barcodeSet.CreatedAt}
                                        </CardSubtitle>
                                        <CardSubtitle tag="h6" className="mb-2 text-muted">
                                            {
                                                tags.map((tag, i) => {
                                                    return (
                                                        <small key={i}>
                                                            <span className="bep-orange" style={{ cursor: 'pointer' }}
                                                                onClick={() => this.onHashtagClick(tag)}>
                                                                #{tag}
                                                            </span>{' '}
                                                        </small>
                                                    );
                                                })
                                            }
                                        </CardSubtitle>
                                        {
                                            barcodeSet.State === BarcodeSetState.Success &&
                                            <Row style={{ margin: 0 }}>
                                                <Col sm={6} md={6} lg={6}>
                                                    <Button id={editTarget} className="btn-bep btn-sm" onClick={onEditClick}>
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </Button>
                                                    <Tooltip placement="bottom" isOpen={barcodeSetItem.showEditTooltip} target={editTarget} toggle={toggleEditTooltip} >
                                                        {ls.edit}
                                                    </Tooltip>
                                                </Col>
                                                <Col sm={6} md={6} lg={6} hidden={barcodeSet.Status !== BarcodeSetStatus.Active}>
                                                    <Button id={activeTarget} className="btn-bep btn-sm" onClick={onActiveClick}>
                                                        <FontAwesomeIcon icon={faCheckCircle} />
                                                    </Button>
                                                    <Tooltip placement="bottom" isOpen={barcodeSetItem.showActiveTooltip} target={activeTarget} toggle={toggleActiveTooltip} >
                                                        {ls.setPassive}
                                                    </Tooltip>
                                                </Col>
                                                <Col sm={6} md={6} lg={6} hidden={barcodeSet.Status !== BarcodeSetStatus.Passive}>
                                                    <Button id={passiveTarget} className="btn-sm" onClick={onPassiveClick}>
                                                        <FontAwesomeIcon icon={faTimesCircle} />
                                                    </Button>
                                                    <Tooltip placement="bottom" isOpen={barcodeSetItem.showPassiveTooltip} target={passiveTarget} toggle={togglePassiveTooltip} >
                                                        {ls.setActive}
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        }
                                        {
                                            barcodeSet.State === BarcodeSetState.Failed &&
                                            <Row style={{ margin: 0 }} >
                                                <Col sm={12} md={12} lg={12}>
                                                    <Button id={failedTarget} className="btn-sm">
                                                        <FontAwesomeIcon icon={faExclamationTriangle} />
                                                    </Button>
                                                    <Tooltip placement="bottom" isOpen={barcodeSetItem.showFailedTooltip} target={failedTarget} toggle={toggleFailedTooltip} >
                                                        {ls.importFailed}
                                                    </Tooltip>
                                                </Col>
                                            </Row>
                                        }
                                    </Card>
                                </Col>);
                        })}
                    </Row>
                </Card>
            </div>
        );
    }
}

interface IProps {
    hidden?: boolean;

    company: ICompany;

    onEditPress?: { (e: IBarcodeSetActionEvent): void }
}

interface IState {
    ls: ILangSupport;

    isHelpOn: boolean;

    querying: boolean;
    alert: FadeAlertMessage;

    search: string;
    page: number;
    count: number;

    barcodeSetItems: IBarcodeSetItem[];
}

interface IBarcodeSetItem {
    barcodeSet: IBarcodeSet;
    showActiveTooltip: boolean;
    showPassiveTooltip: boolean;
    showFailedTooltip: boolean;
    showEditTooltip: boolean;
}

export interface IBarcodeSetActionEvent {
    barcodeSet: IBarcodeSet;
}
