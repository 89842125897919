import * as React from 'react';
import { Alert, Fade } from 'reactstrap';
import { ColorCodes } from './models';

export class FadeAlert extends React.Component<IProps, IState>  {

    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        let props = this.props;
        let alert = props.alert;
        let message = alert.message === null || alert.message === undefined ? '' : alert.message;
        let show = message.length > 0;
        if (show)
            return (
                <Fade in={show} tag={props.tag} className={props.className}>
                    <Alert color={alert.color}>
                        {message}
                        &nbsp;
                        <small style={{ float: 'right', fontSize: '70%' }}>({new Date().toLocaleString()})</small>
                    </Alert>
                </Fade>
            );
        else
            return ('');
    }
}

interface IProps {
    alert: FadeAlertMessage;
    tag?: React.ElementType<any>;
    className?: string;
}

interface IState { }

export class FadeAlertMessage {
    readonly message: string;
    readonly color: string;

    constructor(message: string, color: string) {
        this.message = message;
        this.color = color;
    }

    static readonly default: FadeAlertMessage = new FadeAlertMessage('', ColorCodes.light);
}