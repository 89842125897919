import * as React from 'react';
import { Card } from 'reactstrap';
import { ILangSupport, langHelper } from './Company.lang';
import { IBarcodeSwitchEvent } from './Company.models';
import { TabCompany } from './tab/TabCompany';

export class Company extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            ls: langHelper.Current
        };
    }

    componentDidMount = async (): Promise<void> => {
        langHelper.RegisterComponent(this);
    }
    componentWillUnmount = (): void => {
        langHelper.UnregisterComponent(this);
    }

    render() {
        let props = this.props;

        let state = this.state;
        let ls = state.ls;
        return (
            <div hidden={props.hidden}>
                <Card body>
                    <h5 style={{ position: 'absolute' }}>
                        {ls.title}
                    </h5>
                    <TabCompany onBarcodeSwitch={props.onBarcodeSwitch} />
                </Card>
            </div>
        );
    }
}

interface IProps {
    hidden?: boolean;
    onBarcodeSwitch: { (e: IBarcodeSwitchEvent): void }
}

interface IState {
    ls: ILangSupport;
}
