import { Lang, MultiLangHelper } from "../../../shared/multi.lang";

export interface ILangSupport {
    lang: Lang;

    importFile: string;
    importHistory: string;
    importEdit: string;
}

class TurkishSupport implements ILangSupport {
    lang = Lang.TR;

    importFile = "Dosya Aktarma";
    importHistory = "Aktarma Geçmişi";
    importEdit = "Aktarma Düzenleme";
}
const turkishSupport = new TurkishSupport();

class EnglishSupport implements ILangSupport {
    lang = Lang.EN;

    importFile = "Import File";
    importHistory = "Import History";
    importEdit = "Import Edit";
}
const englishSupport = new EnglishSupport();

const langHelper = new MultiLangHelper<ILangSupport>(turkishSupport, englishSupport);
export { langHelper }
