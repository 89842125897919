import { faAt, faBuilding, faExternalLinkAlt, faPhoneAlt, faQuestionCircle, faSave, faTerminal, faImage, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Button, Card, Col, Collapse, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { FadeAlert, FadeAlertMessage } from '../../../shared/FadeAlert';
import { FileUpload, IOnChangeEvent } from '../../../shared/FileUpload';
import { ColorCodes } from '../../../shared/models';
import { ICompany, IOnSubmitEvent } from '../Company.models';
import { ILangSupport, langHelper } from './EditCompany.lang';
import { companyRequester, IEditCompanyRequest } from '../Company.requester';

export class EditCompany extends React.Component<IProps, IState> {

    private readonly previewImgUrl = '/imgs/placeholder.jpg';

    notFoundPreviewRef = React.createRef<HTMLImageElement>();
    notFoundFileUploadRef = React.createRef<FileUpload>();

    suspiciousPreviewRef = React.createRef<HTMLImageElement>();
    suspiciousFileUploadRef = React.createRef<FileUpload>();

    originalPreviewRef = React.createRef<HTMLImageElement>();
    originalFileUploadRef = React.createRef<FileUpload>();

    constructor(props: IProps) {
        super(props);

        let company = props.company;

        this.state = {
            ls: langHelper.Current,

            isHelpOn: false,

            submitting: false,
            alert: FadeAlertMessage.default,

            id: company.Id,
            name: company.Name ?? '',
            code: company.Code ?? '',
            email: company.Email ?? '',
            phone: company.Phone ?? '',
            originalUrl :company.OriginalUrl ?? '',
            originalImgUrl: company.OriginalImgUrl,
            suspiciousImgUrl: company.SuspiciousImgUrl
        };
    }

    componentDidMount = async (): Promise<void> => {
        langHelper.RegisterComponent(this);
    }
    componentWillUnmount = (): void => {
        langHelper.UnregisterComponent(this);
    }

    private helpButtonClicked = (): void => {
        this.setState({ isHelpOn: !this.state.isHelpOn });
    }

    private nameChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let newValue = e.target.value;
        this.setState({ name: newValue });
    }
    private codeChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let newValue = e.target.value;
        this.setState({ code: newValue });
    }
    private emailChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let newValue = e.target.value;
        this.setState({ email: newValue });
    }
    private phoneChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let newValue = e.target.value;
        this.setState({ phone: newValue });
    }
    

    private originalUrlChanged = (e: React.ChangeEvent<HTMLInputElement>): void => {
        let newValue = e.target.value;
        this.setState({ originalUrl: newValue });
    }
    submit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        e.preventDefault();

        let state = this.state;
        let ls = state.ls;

        this.setState({ submitting: true, alert: FadeAlertMessage.default }, async () => {
            try {
                let request: IEditCompanyRequest = {
                    Id: state.id,

                    Name: state.name,
                    Code: state.code,
                    Email: state.email,
                    Phone: state.phone,
                    OriginalUrl: state.originalUrl,

                    SuspiciousImgUrl: state.suspiciousImgUrl,
                    OriginalImgUrl: state.originalImgUrl,

                    SuspiciousImage: this.getFile(this.suspiciousFileUploadRef),
                    OriginalImage: this.getFile(this.originalFileUploadRef)
                };

                let response = await companyRequester.Edit(request);

                if (!response.Success) {
                    let message = langHelper.GetViaKey(ls, response.Message);
                    let alertMessage = new FadeAlertMessage(message, ColorCodes.warning);
                    this.setState({ submitting: false, alert: alertMessage });
                }
                else {
                    let props = this.props;
                    if (props.onSubmit !== undefined)
                        props.onSubmit({ company: response.Company });

                    this.clearFileUploadAndPreview(this.notFoundPreviewRef, this.notFoundFileUploadRef);
                    this.clearFileUploadAndPreview(this.suspiciousPreviewRef, this.suspiciousFileUploadRef);
                    this.clearFileUploadAndPreview(this.originalPreviewRef, this.originalFileUploadRef);

                    let message = langHelper.GetViaKey(ls, response.Message);
                    let alertMessage = new FadeAlertMessage(message, ColorCodes.success);

                    this.setState({
                        submitting: false, alert: alertMessage,
                        name: '', code: '', phone: '', email: ''
                    });
                }
            } catch (error) {
                let e = error as Error;
                let alertMessage = new FadeAlertMessage(e.message, ColorCodes.danger);
                this.setState({ alert: alertMessage, submitting: false });
            }
        });
    }

    private getFile = (ref: React.RefObject<FileUpload>): File | null => {
        let fileUpload = ref.current;
        if (fileUpload !== null) {
            let fileList = fileUpload.fileList;
            if (fileList !== null) {
                return fileList[0];
            }
        }
        return null;
    }
    private clearFileUploadAndPreview = (filePreviewRef: React.RefObject<HTMLImageElement>, fileUploadRef: React.RefObject<FileUpload>): void => {
        let filePreview = filePreviewRef.current;
        if (filePreview !== null) {
            filePreview.src = this.previewImgUrl;
        }

        let fileUpload = fileUploadRef.current;
        if (fileUpload !== null) {
            fileUpload.clear();
        }
    }
    private onFileChange = (e: IOnChangeEvent, previewRef: React.RefObject<HTMLImageElement>): void => {
        let previewElem = previewRef.current;
        if (previewElem === null)
            return;

        let fileDetails = e.fileDetails;
        if (fileDetails === null)
            return;

        if (fileDetails.length === 0) {
            previewElem.src = this.previewImgUrl;
            return;
        }

        let fileDetail = fileDetails[0];
        previewElem.src = URL.createObjectURL(fileDetail.file);
    }
    private renderImageUpload = (text: string, imgSrc: string, previewImageRef: React.RefObject<HTMLImageElement>, fileUploadRef: React.RefObject<FileUpload>) => {
        let state = this.state;

        let imageUploadAccept = ".png,.jpg,.jpeg";
        let previewImgStyle: React.CSSProperties = { maxWidth: 255, maxHeight: 172, minWidth: 255, minHeight: 172, width: '100%', height: '100%' };

        let src = imgSrc ? imgSrc : this.previewImgUrl;

        return (
            <Col sm={6} md={6} lg={6}>
                <div className="d-flex justify-content-center">
                    <img ref={previewImageRef} src={src} style={previewImgStyle} alt={text} />
                </div>
                <InputGroup className="d-flex justify-content-center">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText className="required-input">
                            <FontAwesomeIcon icon={faImage} />
                        </InputGroupText>
                    </InputGroupAddon>
                    <FileUpload ref={fileUploadRef} className="btn-sm" accept={imageUploadAccept}
                        multiple={false} disabled={state.submitting}
                        onChange={(e) => { this.onFileChange(e, previewImageRef) }}>
                        {text + '*'}
                    </FileUpload>
                </InputGroup>
            </Col>
        );
    }

    render() {
        let state = this.state;
        let ls = state.ls;

        return (
            <div>
                <Card body>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <Button className="btn-sm" color={ColorCodes.dark} onClick={this.helpButtonClicked} style={{ float: 'right' }}>
                                <FontAwesomeIcon icon={faQuestionCircle} />
                            </Button>
                            <Collapse isOpen={state.isHelpOn}>
                                <Card body>
                                    <ul>
                                        <li>{ls.help_ReqiredFields}</li>
                                        <li>{ls.help_InformationalCompanyDetails}</li>
                                    </ul>
                                </Card>
                            </Collapse>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={9} md={9} lg={9}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText className="required-input">
                                            <FontAwesomeIcon icon={faBuilding} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text" placeholder={ls.companyName + '*'} value={state.name} disabled={state.submitting}
                                        onChange={this.nameChanged} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col sm={3} md={3} lg={3}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText className="required-input">
                                            <FontAwesomeIcon icon={faTerminal} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text" placeholder={ls.companyCode + '*'} value={state.code} disabled={state.submitting}
                                        onChange={this.codeChanged} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} md={6} lg={6}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faAt} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text" placeholder={ls.email} value={state.email} disabled={state.submitting}
                                        onChange={this.emailChanged} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col sm={6} md={6} lg={6}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faPhoneAlt} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text" placeholder={ls.phone} value={state.phone} disabled={state.submitting}
                                        onChange={this.phoneChanged} />
                                </InputGroup>
                            </FormGroup>
                        </Col>

                        <Col sm={6} md={6} lg={6}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={faLink} />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text" placeholder={ls.originalUrl} value={state.originalUrl} disabled={state.submitting}
                                        onChange={this.originalUrlChanged} />
                                </InputGroup>
                            </FormGroup>
                        </Col>

                    </Row>
                    <Row>
                        {
                            this.renderImageUpload(ls.suspiciousImage, state.suspiciousImgUrl, this.suspiciousPreviewRef, this.suspiciousFileUploadRef)
                        }
                        {
                            this.renderImageUpload(ls.originalImage, state.originalImgUrl, this.originalPreviewRef, this.originalFileUploadRef)
                        }
                    </Row>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <FadeAlert alert={state.alert} tag="span" className="mt-3" />
                            <div className="d-flex justify-content-center">
                                <Button className="btn-sm btn-bep" disabled={state.submitting} onClick={this.submit}>
                                    <FontAwesomeIcon icon={faSave} />&nbsp;
                                    {ls.save}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

interface IProps {
    company: ICompany;
    onSubmit?: { (e: IOnSubmitEvent): void }
}

interface IState {
    ls: ILangSupport;

    isHelpOn: boolean;

    submitting: boolean;
    alert: FadeAlertMessage;

    id: string;

    name: string;
    code: string;
    email: string;
    phone: string;
    originalUrl: string;
    suspiciousImgUrl: string;
    originalImgUrl: string;
}
