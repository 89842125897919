import { Lang, MultiLangHelper } from "../../shared/multi.lang";

export interface ILangSupport {
    lang: Lang;

    title: string;

    serialNumber: string;
    check: string;
    notFound: string;

    mailSubjectOriginal: string;
    mailBodyOriginal: string;

    mailSubjectSuspicious: string;
    mailBodySuspicious: string;
    warranty: string;
    bepIdIsAServiceOfBepHologram: string;
    forMoreInfo: string;
}

class TurkishSupport implements ILangSupport {
    lang = Lang.TR;

    title = "BepHologram'a hoşgeldiniz";

    serialNumber = "seri numarası";
    check = "sorgula";
    notFound = "üzgünüz, aradığınız bep-id için kayıt bulamadık";

    mailSubjectOriginal = "Orijinal Ürün \"{0}\"";
    mailBodyOriginal = "Selamlar, BEP-ID uygulamasını kullanarak \"{0}\" kodlu ürününüzü \"https://www.bep-id.com/{0}\" linki üzerinden başarıyla doğruladım. Teşekkür eder, iyi günler dilerim.";
    warranty ="Ürününüzü garanti kapsamında kayıt etmek istiyorsanız tıklayınız"
    mailSubjectSuspicious = "Şüpheli Ürün \"{0}\"";
    mailBodySuspicious = "Selamlar, BEP-ID uygulamasını kullanarak \"{0}\" kodlu ürününüzü\"https://www.bep-id.com/{0}\" linki üzerinden doğrularken şüpheli ürün uyarısı aldım. Bilgilerinize sunar, iyi günler dilerim.";

    bepIdIsAServiceOfBepHologram = "BEP-ID, BEP HOLOGRAM A.Ş.'nin bir hizmetidir";
    forMoreInfo = "bilgi için";
}
const turkishSupport = new TurkishSupport();

class EnglishSupport implements ILangSupport {
    lang = Lang.EN;

    title = "Welcome to BepHologram";

    serialNumber = "serial number";
    check = "check";
    notFound = "sorry, we couldn't find any records with this bep-id";

    mailSubjectOriginal = "Original Product \"{0}\"";
    mailBodyOriginal = "Hi, i was able to verify your \"{0}\" coded product successfully, using BEP-ID application over \"https://www.bep-id.com/{0}\" address. Thank you, have a nice day.";
    warranty = "If you want to register your product under warranty click here"

    mailSubjectSuspicious = "Suspicious Product \"{0}\"";
    mailBodySuspicious = "Hi, i came accross a suspicious product warning while i was trying to verify your \"{0}\" coded product, using BEP-ID application over \"https://www.bep-id.com/{0}\" address. For your information, have a nice day.";

    bepIdIsAServiceOfBepHologram = "BEP-ID is a service of BEP HOLOGRAM A.Ş.";
    forMoreInfo = "for more information";
}
const englishSupport = new EnglishSupport();

const langHelper = new MultiLangHelper<ILangSupport>(turkishSupport, englishSupport);
export { langHelper }
