import * as React from 'react';
import { TagsInput } from 'react-tag-input-component';

export class Tags extends React.Component<IProps, IState>  {

    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        let props = this.props;
        return (
            <TagsInput value={props.value} onChange={props.onChange} name={props.name} placeHolder={props.placeHolder} />
        );
    }
}

interface IProps {
    className?: string;
    value: string[];
    onChange: { (tags: string[]): void };
    placeHolder?: string;
    name?: string;
}

interface IState {
}