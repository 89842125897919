import * as React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import { Barcode } from './barcode/Barcode';
import { Company } from './company/Company';
import { IBarcodeSwitchEvent, ICompany } from './company/Company.models';
import { ILangSupport, langHelper } from './Dashboard.lang';

enum Breadcrumbs { companies = 'companies', barcodes = 'barcodes' }

export class Dashboard extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            ls: langHelper.Current,

            breadcrumb: Breadcrumbs.companies,
            company: undefined
        };
    }

    componentDidMount = (): void => {
        langHelper.RegisterComponent(this);
    }
    componentWillUnmount = (): void => {
        langHelper.UnregisterComponent(this);
    }

    onBarcodeSwitch = (e: IBarcodeSwitchEvent): void => {
        this.setState({ breadcrumb: Breadcrumbs.barcodes, company: e.company });
    }
    onCompanyBreadcrumbClick = (): void => {
        this.setState({ breadcrumb: Breadcrumbs.companies, company: undefined });
    }

    render() {
        let state = this.state;
        let ls = state.ls;

        let companyBreadcrumbStyle: React.CSSProperties = { cursor: 'pointer', color: '#f3632a' };

        return (
            <div>
                <Breadcrumb hidden={state.breadcrumb !== Breadcrumbs.companies}>
                    <BreadcrumbItem style={companyBreadcrumbStyle} active>{ls.breadcrumbCompanies}</BreadcrumbItem>
                </Breadcrumb>
                <Breadcrumb hidden={state.breadcrumb !== Breadcrumbs.barcodes}>
                    <BreadcrumbItem style={companyBreadcrumbStyle} onClick={this.onCompanyBreadcrumbClick}>{ls.breadcrumbCompanies}</BreadcrumbItem>
                    {
                        state.company !== undefined &&
                        <BreadcrumbItem active>{state.company.Name}</BreadcrumbItem>
                    }
                    <BreadcrumbItem active>{ls.breadcrumbBarcodes}</BreadcrumbItem>
                </Breadcrumb>
                {
                    state.breadcrumb === Breadcrumbs.barcodes && state.company !== undefined &&
                    <Barcode company={state.company} />
                }
                <Company hidden={state.breadcrumb !== Breadcrumbs.companies} onBarcodeSwitch={this.onBarcodeSwitch} />
            </div>
        );
    }
}

interface IProps { }

interface IState {
    ls: ILangSupport

    breadcrumb: Breadcrumbs;
    company?: ICompany;
}

//import axios, { AxiosRequestConfig } from 'axios';

//const api = axios.create({
//    baseURL: 'https://localhost:44335/admin/dashboard'
//});


//api.interceptors.request.use((config: AxiosRequestConfig) => {
//    const token = localStorage.getItem('token');
//    if (token) { config.headers.Authorization = `Bearer ${token}`; } return config;
//}); export default api;
