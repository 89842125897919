import * as React from 'react';
import { ILangSupport, langHelper } from './TabCompany.lang';
import { NewCompany } from './../new/NewCompany';
import { ICompanyActionEvent, ListCompany } from './../list/ListCompany';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlusCircle, faTh } from '@fortawesome/free-solid-svg-icons';
import { companyRequester } from '../Company.requester';
import { IBarcodeSwitchEvent, ICompany, IOnSubmitEvent } from '../Company.models';
import { EditCompany } from '../edit/EditCompany';

enum Tabs { new = 'new', list = 'list', edit = 'edit' }

export class TabCompany extends React.Component<IProps, IState> {
    private readonly listCompanyRef = React.createRef<ListCompany>();

    constructor(props: IProps) {
        super(props);
        this.state = {
            ls: langHelper.Current,

            activeTab: Tabs.list,

            editModeModel: undefined,
            deleteModal: { isOpen: false, id: '', title: '' }
        };
    }

    componentDidMount = async (): Promise<void> => {
        langHelper.RegisterComponent(this);
    }
    componentWillUnmount = (): void => {
        langHelper.UnregisterComponent(this);
    }

    //reactions to the buttons of list component
    private onEditPress = (e: ICompanyActionEvent): void => {
        this.setState({ editModeModel: { company: e.company } }, () => {
            this.toggle(Tabs.edit);
        });
    }
    private onQrPress = (e: ICompanyActionEvent): void => {
        let props = this.props;

        props.onBarcodeSwitch({ company: e.company });
    }
    private onDeletePress = (e: ICompanyActionEvent): void => {
        let state = this.state;
        let deleteModal = state.deleteModal;

        let initializeModal = (): void => {
            let company = e.company;
            deleteModal.id = company.Id;
            deleteModal.title = company.Name;
            deleteModal.isOpen = true;
            this.setState({ deleteModal: deleteModal });
        }

        if (deleteModal.isOpen) {
            deleteModal.isOpen = false;
            this.setState({ deleteModal: deleteModal }, initializeModal);
        }
        else
            initializeModal();
    }

    //Edit mode
    private onCompanyEdit = (e: IOnSubmitEvent): void => {
        this.reloadCompanies();
        this.toggle(Tabs.list);
    }
    private reloadCompanies = (): void => {
        let listCompany = this.listCompanyRef.current;
        if (listCompany === null)
            return;
        listCompany.list();
    }
    private onNewRecord = (e: IOnSubmitEvent): void => {
        this.reloadCompanies();
    }

    //delete mode
    private toggleDeleteModal = (): void => {
        let modalModel = this.state.deleteModal;
        modalModel.isOpen = !modalModel.isOpen;
        this.setState({ deleteModal: modalModel })
    }
    private deleteButtonClicked = (): void => {
        let state = this.state;
        let deleteModal = state.deleteModal;

        let companyId = deleteModal.id;

        deleteModal.id = '';
        deleteModal.title = '';
        deleteModal.isOpen = false;

        this.setState({ deleteModal: deleteModal }, async () => {
            await companyRequester.Delete({ Id: companyId });
            this.reloadCompanies();
        });
    }

    //other
    private toggle = (tab: Tabs) => {
        let state = this.state;

        if (state.activeTab === tab)
            return;

        let editModeModel = tab === Tabs.edit ? state.editModeModel : undefined;
        this.setState({ activeTab: tab, editModeModel: editModeModel });
    }
    private getClassName = (tab: Tabs) => {
        return this.state.activeTab === tab ? 'active' : '';
    }

    render() {
        let state = this.state;
        let ls = state.ls;

        let activeTab = state.activeTab;

        let editModeModel = state.editModeModel;
        let deleteModal = state.deleteModal;

        return (
            <div>
                <Nav tabs>
                    <NavItem style={{ cursor: 'pointer', marginLeft: 'auto' }}>
                        <NavLink className={this.getClassName(Tabs.list)}
                            onClick={() => { this.toggle(Tabs.list); }}>
                            <FontAwesomeIcon icon={faTh} />&nbsp;
                            {ls.list}
                        </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: 'pointer' }}>
                        <NavLink className={this.getClassName(Tabs.new)}
                            onClick={() => { this.toggle(Tabs.new); }}>
                            <FontAwesomeIcon icon={faPlusCircle} />&nbsp;
                            {ls.addNew}
                        </NavLink>
                    </NavItem>
                    {
                        editModeModel &&
                        <NavItem style={{ cursor: 'pointer' }}>
                            <NavLink className={this.getClassName(Tabs.edit)}
                                onClick={() => { this.toggle(Tabs.edit); }}>
                                <FontAwesomeIcon icon={faEdit} />&nbsp;
                                {ls.edit}
                            </NavLink>
                        </NavItem>
                    }
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={Tabs.list}>
                        <ListCompany ref={this.listCompanyRef}
                            onEditPress={(this.onEditPress)} onQrPress={this.onQrPress} onDeletePress={this.onDeletePress} />
                    </TabPane>
                    <TabPane tabId={Tabs.new}>
                        <NewCompany onSubmit={this.onNewRecord} />
                    </TabPane>
                    {
                        editModeModel &&
                        <TabPane tabId={Tabs.edit}>
                            <EditCompany company={editModeModel.company} onSubmit={this.onCompanyEdit} />
                        </TabPane>
                    }
                </TabContent>

                <Modal isOpen={deleteModal.isOpen} toggle={this.toggleDeleteModal} style={{ textAlign: 'center' }}>
                    <ModalHeader toggle={this.toggleDeleteModal}>{deleteModal.title}</ModalHeader>
                    <ModalBody>
                        {ls.youAreAboutToDelete}
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-sm btn-bep" onClick={this.deleteButtonClicked}>{ls.yesDelete}</Button>{' '}
                        <Button className="btn-sm" onClick={(this.toggleDeleteModal)}>{ls.noDontDelete}</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

interface IProps {
    onBarcodeSwitch: { (e: IBarcodeSwitchEvent): void }
}

interface IState {
    ls: ILangSupport;

    activeTab: string;

    editModeModel?: IEditModeModel;
    deleteModal: IDeleteModalModel;
}

interface IEditModeModel {
    company: ICompany;
}

interface IDeleteModalModel {
    isOpen: boolean;
    id: string;
    title: string;
}
