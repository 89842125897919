import * as React from 'react';
import { ILangSupport, langHelper } from './Home.lang';
import { BarcodeCheck } from './barcode/BarcodeCheck';

export class Home extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            ls: langHelper.Current
        };
    }

    componentDidMount = (): void => {
        langHelper.RegisterComponent(this);
    }
    componentWillUnmount = (): void => {
        langHelper.UnregisterComponent(this);
    }

    render() {
        //let state = this.state;
        //let ls = state.ls;
        return (
            <BarcodeCheck />
        );
    }
}

interface IProps { }

interface IState {
    ls: ILangSupport;
}