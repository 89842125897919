import { Lang, MultiLangHelper } from "../../../shared/multi.lang";

export interface ILangSupport {
    lang: Lang;

    list: string;
    addNew: string;
    edit: string;

    areYouSure: string;
    youAreAboutToDelete: string;
    yesDelete: string;
    noDontDelete: string;
}

class TurkishSupport implements ILangSupport {
    lang = Lang.TR;

    list = 'listele';
    addNew = 'yeni ekle';
    edit = 'düzenle';

    areYouSure = "emin misiniz?";
    youAreAboutToDelete = "bir şirket kaydını silmek üzeresiniz, emin misiniz?";
    yesDelete = "eminim, sil";
    noDontDelete = "hayır, iptal et!";
}
const turkishSupport = new TurkishSupport();

class EnglishSupport implements ILangSupport {
    lang = Lang.EN;

    list = 'list';
    addNew = 'add new';
    edit = 'edit';

    areYouSure = "are you sure?";
    youAreAboutToDelete = "you are about to delete a company record, are you sure?";
    yesDelete = "sure, delete it";
    noDontDelete = "nope, cancel!";
}
const englishSupport = new EnglishSupport();

const langHelper = new MultiLangHelper<ILangSupport>(turkishSupport, englishSupport);
export { langHelper }
