import { Lang, MultiLangHelper } from "../../../shared/multi.lang";

export interface ILangSupport {
    lang: Lang;

    search: string;
    edit: string;
    qr: string;
    delete: string;

    help_startTyping: string;
    help_justPressEnter: string;
    help_barcodeMenu: string;
}

class TurkishSupport implements ILangSupport {
    lang = Lang.TR;

    search = "ara";
    edit = "düzenle";
    qr = "qr kod";
    delete = "sil";

    help_startTyping = "arama kutucuğuna bulmak istediğiniz şirketin ismini yazmaya başlayın, arama işlemi siz yazmayı bıraktıktan kısa bir süre sonra otomatik olarak başlayacaktır";
    help_justPressEnter = "eğer beklemek istemiyorsanız enter'a basarak arama işlemini anında başlatabilirsiniz";
    help_barcodeMenu = "qr kod menüsüne erişmek için öncelikle bir şirket kaydı oluşturmalı ve şirket kartındaki 'qr kod' butonunu kullanmalısınız";
}
const turkishSupport = new TurkishSupport();

class EnglishSupport implements ILangSupport {
    lang = Lang.EN;

    search = "search";
    edit = "edit";
    qr = "qr code";
    delete = "delete";

    help_startTyping = "start typing the name of the company you want to find, the search operation will start right after you stop typing";
    help_justPressEnter = "in case you don't want to wait, you might as well press 'enter' in order to start the search operation right away";
    help_barcodeMenu = "you need to create a company and then use the 'qr code' button on the company card in order to access to the qr code menu";
}
const englishSupport = new EnglishSupport();

const langHelper = new MultiLangHelper<ILangSupport>(turkishSupport, englishSupport);
export { langHelper }
