export interface IBarcode {

}

export interface IBarcodeSet {
    Id: string;
    CreatedAt: Date;

    UploadedFileName: string;
    Tags: string[];
    BarcodeCount: number;
    IssueCount: number;

    State: BarcodeSetState;
    Status: BarcodeSetStatus;
}

export enum BarcodeSetState {
    Created = 0,
    Importing = 1,
    Approved = 2,

    Failed = 255,
    Success = 256,
}

export enum BarcodeSetStatus {
    Active = 0,
    Passive = 1
}