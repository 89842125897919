import requester from "../../shared/requester";
import { IResponse } from "../../shared/models"
import { AxiosRequestConfig } from "axios";
import { IBarcodeSet } from "./Barcode.models";

class BarcodeRequester {
    private readonly controller = 'barcode';

    Import = async (request: IImportBarcodeRequest): Promise<IImportBarcodeResponse> => {
        let config: AxiosRequestConfig = {
            headers: { 'Content-Type': 'multipart/form-data' }
        };

        let formData = new FormData();

        if (request.CompanyId)
            formData.append("CompanyId", request.CompanyId);

        if (request.File)
            formData.append("File", request.File);

        if (request.Tags)
            formData.append("TagsJson", JSON.stringify(request.Tags));

        let resp = await requester.post<IImportBarcodeResponse>(`${this.controller}/import`, formData, config);
        return resp.data;
    }

    SetList = async (request: IListBarcodeSetsRequest): Promise<IListBarcodeSetsResponse> => {
        let config: AxiosRequestConfig = {
            params: request
        };
        let resp = await requester.get<IListBarcodeSetsResponse>(`${this.controller}/set/list`, config);
        return resp.data;
    }

    SetBarcodeSetStatus = async (request: ISetBarcodeSetStatusRequest): Promise<ISetBarcodeSetStatusResponse> => {
        let resp = await requester.post<ISetBarcodeSetStatusResponse>(`${this.controller}/set/setStatus`, request);
        return resp.data;
    }

    EditBarcodeSet = async (request: IEditBarcodeSetRequest): Promise<IEditBarcodeSetResponse> => {
        let innerRequest = { Id: request.Id, TagsJson: JSON.stringify(request.Tags) };
        let resp = await requester.post<IEditBarcodeSetResponse>(`${this.controller}/set/edit`, innerRequest);
        return resp.data;
    }
}
const barcodeRequester = new BarcodeRequester();
export { barcodeRequester };

export interface IImportBarcodeRequest {
    CompanyId: string;
    File?: File | null;
    Tags: string[];
}
export interface IImportBarcodeResponse extends IResponse {

}

export interface IListBarcodeSetsRequest {
    Search: string;
    Count: number;
    Page: number;
    CompanyId?: string;
}

export interface IListBarcodeSetsResponse extends IResponse {
    BarcodeSets: IBarcodeSet[];
}

export interface IEditBarcodeSetRequest {
    Id: string;
    Tags: string[];
}

export interface IEditBarcodeSetResponse extends IResponse {

}

export interface ISetBarcodeSetStatusRequest {
    Id: string;
    BarcodeSetStatus: number;
}

export interface ISetBarcodeSetStatusResponse extends IResponse {

}