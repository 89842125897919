import { Lang, MultiLangHelper } from "../shared/multi.lang";

export interface ILangSupport {
    lang: Lang;

    records: string;
    somethingIsWrongHere: string;
    xRecordsHaveIssues: string;
}

class TurkishSupport implements ILangSupport {
    lang = Lang.TR;

    records = "kayıt";
    somethingIsWrongHere = "bu kayıtta bir problem var";
    xRecordsHaveIssues = "kayıt problemli";
}
const turkishSupport = new TurkishSupport();

class EnglishSupport implements ILangSupport {
    lang = Lang.EN;

    records = "records";
    somethingIsWrongHere = "there's something wrong with this record";
    xRecordsHaveIssues = "records have issues";
}
const englishSupport = new EnglishSupport();

const langHelper = new MultiLangHelper<ILangSupport>(turkishSupport, englishSupport);
export { langHelper }
