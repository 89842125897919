import { Component } from "react";

export class Lang {
    public readonly Code: string;
    public readonly Name: string;

    constructor(code: string, name: string) {
        this.Code = code;
        this.Name = name;
    }

    //easy access
    static readonly TR = new Lang("TR", "Türkçe");
    static readonly EN = new Lang("EN", "English");

    //list & query langs
    static readonly GetAll = () => {
        return [Lang.TR, Lang.EN];
    }
    static readonly GetViaCode = (code: string): Lang => {
        let allLangs = Lang.GetAll();
        for (let i = 0; i < allLangs.length; i++) {
            let lang = allLangs[i];
            if (lang.Code === code)
                return lang;
        }
        throw new Error(`Couldn't find any Lang with code '${code}'`);
    }

    //current lang
    private static current = Lang.TR;
    static get Current(): Lang {
        return this.current;
    }
    static set Current(lang: Lang) {
        let changed = this.current !== lang;
        this.current = lang;

        if (changed)
            LangChangeHander.HandleChange(lang);
    }
}

export class MultiLangHelper<TLangSupport extends { lang: Lang }> {
    private readonly langSupports: TLangSupport[];

    private current: TLangSupport;
    get Current(): TLangSupport {
        return this.current;
    }

    constructor(...langSupports: TLangSupport[]) {
        this.langSupports = langSupports;

        let allLangs = Lang.GetAll();
        for (var i = 0; i < allLangs.length; i++) {
            let lang = allLangs[i];

            let exist = false;
            for (let i = 0; i < langSupports.length; i++) {
                let langSupport = langSupports[i];
                if (langSupport.lang === lang) {
                    exist = true;
                    break;
                }
            }

            if (!exist)
                throw new Error(`You have to provide ${lang.Code} support`);
        }

        this.current = this.GetSupportViaLang(Lang.Current);
    }

    private readonly registrations: { component: Component<any, { ls: TLangSupport }>, callback: { (newLang: Lang): void } }[] = [];
    readonly RegisterComponent = (component: Component<any, { ls: TLangSupport }>): void => {
        let registrations = this.registrations;

        //check existing registration
        for (let i = 0; i < registrations.length; i++) {
            let registration = registrations[i];
            if (registration.component === component)
                return;//this component is already registered so cancel the registration by returning from here
        }

        //register to LangChangeHander
        let callback = (newLang: Lang) => {
            let langSupport = this.GetSupportViaLang(newLang);
            component.setState({ ls: langSupport });
        };
        callback(Lang.Current);
        LangChangeHander.RegisterOnChange(callback);

        //register here
        let newRegistration = { component: component, callback: callback };
        registrations.push(newRegistration);
    }
    readonly UnregisterComponent = (component: Component<any, { ls: TLangSupport }>): void => {
        let registrations = this.registrations;

        //check existing registration
        for (let i = 0; i < registrations.length; i++) {
            let registration = registrations[i];
            if (registration.component === component) {
                LangChangeHander.UnregisterOnChange(registration.callback);
                registrations.splice(i, 1);
            }
        }
    }

    private readonly GetSupportViaLang = (lang: Lang): TLangSupport => {
        for (let i = 0; i < this.langSupports.length; i++) {
            if (this.langSupports[i].lang === lang)
                return this.langSupports[i];
        }
        throw new Error(`Couldn't find LangSupport with code '${lang}'`);
    }

    readonly GetViaKey = (langSupport: TLangSupport, key: string): string => {
        return Object.entries<any>(langSupport).filter(x => x[0] === key)[0][1] as string;
    }
}

class LangChangeHander {
    private static readonly onChangeCallbacks: { (newLang: Lang): void }[] = [];

    static readonly RegisterOnChange = (callback: { (newLang: Lang): void }) => {
        LangChangeHander.onChangeCallbacks.push(callback);
    }
    static readonly UnregisterOnChange = (callback: { (newLang: Lang): void }) => {
        let onChangeCallbacks = LangChangeHander.onChangeCallbacks;
        for (let i = 0; i < onChangeCallbacks.length; i++) {
            let onChangeCallback = onChangeCallbacks[i];
            if (onChangeCallback === callback)
                onChangeCallbacks.splice(i, 1);
        }
    }
    static readonly HandleChange = (newLang: Lang): void => {
        let onChangeCallbacks = LangChangeHander.onChangeCallbacks;
        for (let i = 0; i < onChangeCallbacks.length; i++) {
            let onChangeCallback = onChangeCallbacks[i];
            onChangeCallback(newLang);
        }
    }
}
