import { Lang, MultiLangHelper } from "../../../shared/multi.lang";

export interface ILangSupport {
    lang: Lang;

    help_ReqiredFields: string;
    help_InformationalCompanyDetails: string;

    companyName: string;
    companyCode: string;
    email: string;
    phone: string;

    suspiciousUrl: string;
    originalUrl: string;

    suspiciousImage: string;
    originalImage: string;

    password: string;

    save: string;

    'CompanyController.New.Name': string;
    'CompanyController.New.Code': string;
    'CompanyController.New.Email': string;
    'CompanyController.New.Phone': string;
    'CompanyController.New.OriginalUrl': string;
    'CompanyController.New.SuspiciousUrl': string;
    'CompanyController.New.imgtype': string;
    'CompanyController.New.NotFoundImage': string;
    'CompanyController.New.SuspiciousImage': string;
    'CompanyController.New.OriginalImage': string;
    'CompanyController.New.existingCompany': string;
    'CompanyController.New.companyViaCode': string;
    'CompanyController.New': string;
}

class TurkishSupport implements ILangSupport {
    lang = Lang.TR;

    help_ReqiredFields = "(*) ile işaretlenmiş alanlar zorunlu alanlardır";
    help_InformationalCompanyDetails = "'eposta' ve 'telefon numarası' gibi alanlar zorunlu olmamakla beraber, kullanıcıların telefon ekranından kolayca bu bilgileri kopyalayabilmesi için tasarlandı";

    companyName = "şirket adı";
    companyCode = "şirket kodu";
    email = "eposta";
    phone = "telefon numarası";

    suspiciousUrl = "'şüpheli kod' linki";
    originalUrl = "ürün garanti etme linki";

    suspiciousImage = "'şüpheli kod' görseli";
    originalImage = "'orijinal kod' görseli";

    password = "şifre";

    save = "kaydet";
    'CompanyController.New.Name' = "lütfen şirket adı belirtin";
    'CompanyController.New.Code' = "lütfen şirket kodu belirtin";
    'CompanyController.New.Email' = "lütfen geçerli bir email belirtin";
    'CompanyController.New.Phone' = "lütfen geçerli bir telefon numarası belirtin";
    'CompanyController.New.OriginalUrl' = "lütfen geçerli bir 'orijinal kod' linki belirtin";
    'CompanyController.New.SuspiciousUrl' = "lütfen geçerli bir 'şüpheli kod' linki belirtin";
    'CompanyController.New.imgtype' = "lütfen sadece png formatında görsel yükleyin";
    'CompanyController.New.NotFoundImage' = "lütfen 'bilinmeyen kod' görseli ekleyin";
    'CompanyController.New.SuspiciousImage' = "lütfen 'şüpheli kod' görseli ekleyin";
    'CompanyController.New.OriginalImage' = "lütfen 'orijinal kod' görseli ekleyin";
    'CompanyController.New.existingCompany' = "kaydetmeye çalıştığınız şirket zaten mevcut";
    'CompanyController.New.companyViaCode' = "aynı koda sahip başka bir şirket zaten mevcut";
    'CompanyController.New' = "yeni şirket kaydı başarıyla eklendi";
    'CompanyController.New.Password' = "Hatalı şifre! Doğru şifre ile ekleme işlemi yapabilirsiniz";
}
const turkishSupport = new TurkishSupport();

class EnglishSupport implements ILangSupport {
    lang = Lang.EN;

    help_ReqiredFields = "fields that are marked with (*) are required";
    help_InformationalCompanyDetails = "even thought fields like 'email' and 'phone number' are not required, they are designed to help mobile users in order for them to easily copy those informations";

    companyName = "company name";
    companyCode = "company code";
    email = "email";
    phone = "phone number";

    suspiciousUrl = "'suspicious code' link";
    originalUrl = "product warranty link";

    suspiciousImage = "'suspicious code' image";
    originalImage = "'original code' image";

    password = "password";

    save = "save";
    'CompanyController.New.Name' = "please provide the company name";
    'CompanyController.New.Code' = "please provide the company code";
    'CompanyController.New.Email' = "please provide a valid email";
    'CompanyController.New.Phone' = "please provide a valid phone number";
    'CompanyController.New.OriginalUrl' = "please provide a valid 'original code' link";
    'CompanyController.New.SuspiciousUrl' = "please provide a valid 'suspicious code' link";
    'CompanyController.New.imgtype' = "please upload images in png format only";
    'CompanyController.New.NotFoundImage' = "please provide the 'unkown code' image";
    'CompanyController.New.SuspiciousImage' = "please provide the 'suspicious code' image";
    'CompanyController.New.OriginalImage' = "please provide the 'original code' image";
    'CompanyController.New.existingCompany' = "the company you are trying to save already exists";
    'CompanyController.New.companyViaCode' = "there's another company using the same code";
    'CompanyController.New' = "new record of the company was successfully added";
    'CompanyController.New.Password' = "Wrong password! you can add with the correct password."
}
const englishSupport = new EnglishSupport();

const langHelper = new MultiLangHelper<ILangSupport>(turkishSupport, englishSupport);
export { langHelper }
