export interface IResponse {
    Success: boolean;
    Message: string;
}

export class ColorCodes {
    static readonly primary = 'primary';
    static readonly secondary = 'secondary';
    static readonly success = 'success';
    static readonly danger = 'danger';
    static readonly warning = 'warning';
    static readonly info = 'info';
    static readonly light = 'light';
    static readonly dark = 'dark';
}