import requester from "../../shared/requester";
import { IResponse } from "../../shared/models"
import { ICompany } from "./Company.models";
import { AxiosRequestConfig } from "axios";

class CompanyRequester {
    private readonly controller = 'company';

    List = async (request: IListCompaniesRequest): Promise<IListCompaniesResponse> => {
        let config: AxiosRequestConfig = {
            params: request
        };
        let resp = await requester.get<IListCompaniesResponse>(`${this.controller}/list`, config);
        return resp.data;
    }

    New = async (request: INewCompanyRequest): Promise<INewCompanyResponse> => {
        let config: AxiosRequestConfig = {
            headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Basic ' + Buffer.from(request.Password).toString('base64') }
        };

        let formData = new FormData();

        if (request.Name)
            formData.append("Name", request.Name);
        if (request.Code)
            formData.append("Code", request.Code);
        if (request.Email)
            formData.append("Email", request.Email);
        if (request.Phone)
            formData.append("Phone", request.Phone);

        if (request.OriginalUrl)
            formData.append("OriginalUrl", request.OriginalUrl);

        if (request.SuspiciousImage)
            formData.append("SuspiciousImage", request.SuspiciousImage);
        if (request.OriginalImage)
            formData.append("OriginalImage", request.OriginalImage);

        let resp = await requester.post<INewCompanyResponse>(`${this.controller}/new`, formData, config);
        return resp.data;
    }

    Edit = async (request: IEditCompanyRequest): Promise<IEditCompanyResponse> => {
        let config: AxiosRequestConfig = {
            headers: { 'Content-Type': 'multipart/form-data' }
        };

        let formData = new FormData();

        if (request.Id)
            formData.append("Id", request.Id);

        if (request.Name)
            formData.append("Name", request.Name);
        if (request.Code)
            formData.append("Code", request.Code);
        if (request.Email)
            formData.append("Email", request.Email);
        if (request.Phone)
            formData.append("Phone", request.Phone);

        if (request.OriginalUrl)
            formData.append("OriginalUrl", request.OriginalUrl);

        if (request.SuspiciousImgUrl)
            formData.append("SuspiciousImgUrl", request.SuspiciousImgUrl);
        if (request.OriginalImgUrl)
            formData.append("OriginalImgUrl", request.OriginalImgUrl);

        if (request.SuspiciousImage)
            formData.append("SuspiciousImage", request.SuspiciousImage);
        if (request.OriginalImage)
            formData.append("OriginalImage", request.OriginalImage);

        let resp = await requester.post<IEditCompanyResponse>(`${this.controller}/edit`, formData, config);
        return resp.data;
    }

    Delete = async (request: IDeleteCompanyRequest): Promise<IDeleteCompanyResponse> => {
        let resp = await requester.post<IDeleteCompanyResponse>(`${this.controller}/delete`, request);
        return resp.data;
    }
}
const companyRequester = new CompanyRequester();
export { companyRequester };

//List
export interface IListCompaniesRequest {
    Search: string;
    Page: number;
    Count: number;
}
export interface IListCompaniesResponse extends IResponse {
    Companies: ICompany[];
}

//New
export interface INewCompanyRequest {
    Name: string;
    Code: string;
    Email: string;
    Phone: string;
    OriginalUrl: string;
    SuspiciousImage?: File | null;
    OriginalImage?: File | null;

    Password: string;
}
export interface INewCompanyResponse extends IResponse {
    Company: ICompany;
}

//Edit
export interface IEditCompanyRequest {
    Id: string;

    Name: string;
    Code: string;
    Email: string;
    Phone: string;
    OriginalUrl: string;

    SuspiciousImgUrl: string;
    OriginalImgUrl: string;

    SuspiciousImage?: File | null;
    OriginalImage?: File | null;
}
export interface IEditCompanyResponse extends IResponse {
    Company: ICompany;
}

//Delete
export interface IDeleteCompanyRequest {
    Id: string;
}
export interface IDeleteCompanyResponse extends IResponse {
}