import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './layout/Layout';
import { Home } from './home/Home';
import { Dashboard } from './admin/Dashboard'

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/:serialNumber?' component={Home} />
                <Route path='/admin/dashboard' component={Dashboard} />
            </Layout>
        );
    }
}
